
import { TUIStore } from "@tencentcloud/chat-uikit-engine";
import TUIChatEngine, { IMessageModel } from "@tencentcloud/chat-uikit-engine";
import { computed } from "../../adapter-vue";
import messageBubble from "../../components/TUIChat/message-list/message-elements/message-bubble.vue";
import MessageCallGroup from "./message-call/message-call-group.vue";
import MessageCallC2C from "./message-call/message-call-c2c.vue";
interface IProps {
  message: IMessageModel;
  blinkMessageIDList?: string[];
}
const __sfc_main = {};
__sfc_main.props = {
  message: {
    key: "message",
    required: false,
    type: null,
    default: () => ({}) as IMessageModel
  },
  blinkMessageIDList: {
    key: "blinkMessageIDList",
    required: false,
    type: Array,
    default: () => [] as string[]
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emits = __ctx.emit;
  const messageModel = computed(() => TUIStore.getMessageModel(props.message?.ID));
  const messageSignalingInfo = computed(() => messageModel?.value?.getSignalingInfo());
  const messageCustomContent = computed(() => messageModel?.value?.getMessageContent());
  // 需要展示ui的判断逻辑
  const showStyle = computed(() => {
    if (messageSignalingInfo?.value?.businessID === 1) {
      if (messageModel.value?.conversationType === TUIChatEngine.TYPES.CONV_C2C) {
        return "bubble";
      }
      if (messageModel.value?.conversationType === TUIChatEngine.TYPES.CONV_GROUP) {
        return "tip";
      }
    }
    return "";
  });

  // 以下为messageTool等外部交互使用，无需特殊处理，勿动
  const resendMessage = (message: IMessageModel) => {
    emits("resendMessage", message);
  };
  const handleToggleMessageItem = (e: any, message: IMessageModel, isLongpress = false) => {
    emits("handleToggleMessageItem", e, message, isLongpress);
  };
  const handleH5LongPress = (e: any, message: IMessageModel, type: string) => {
    emits("handleH5LongPress", e, message, type);
  };
  return {
    props,
    messageModel,
    messageSignalingInfo,
    messageCustomContent,
    showStyle,
    resendMessage,
    handleToggleMessageItem,
    handleH5LongPress
  };
};
__sfc_main.components = Object.assign({
  MessageCallGroup,
  messageBubble,
  MessageCallC2C
}, __sfc_main.components);
export default __sfc_main;
