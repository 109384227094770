import base from '../module/api/base';
// 导入接口域名列表
import axios from '../common/http'; // 导入http中创建的axios实例

const article = {
        //登录 密码
        login(params) {
            return axios.post(`${base.auth_url}token`, params)
        },
        //登陆 验证码
        smstoken(params){
            return axios.post(`${base.auth_url}smstoken`, params)
        },
        //获取验证码
        sms(phone){
            return axios.get(`${base.auth_url}sms/${phone}`)
        },
        //注册
        register(data){
            return axios.post(`${base.auth_url}user`,data)
        }
}

export default article;