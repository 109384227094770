
import Vue from "vue";
import { TUIGlobal, TUIStore, StoreName,TUIConversationService } from "@tencentcloud/chat-uikit-engine";
import { TUISearch, TUIConversation, TUIChat, } from "../TUIKit";
// import { TUICallKit } from '@tencentcloud/call-uikit-vue2.6';
import { TUICallKit } from '@tencentcloud/call-uikit-vue2';
import mixin from "@/utils/mixin";
export default Vue.extend({
  mixins: [mixin],
  components: {
    TUISearch,
    TUIConversation,
    TUIChat,
    TUICallKit,
  },
  data() {
    return {
      currentModel: "message",
      isH5: TUIGlobal.getPlatform() === "h5",
      currentConversationID: "",
      TUICallKit: true,
    };
  },
  methods:{
        //催医生沟通
    async AskUrge(){
      let userInfo = JSON.parse(localStorage.getItem('userinfo'))
      let res = await this.$api.article.InternetHospitalGet({search_db:'AskUrge',ih_type:localStorage.getItem('ih_type')},{id:this.$route.query.id,pharmacy_id:this.$route.query.pharmacy_id,user_id:userInfo.user_id})
      if(res.data.code == '200'){
        this.$message({
          message: '已催医生，请稍后',
          type: 'success'
        });
      }
    },
    beforeCalling(){
      this.currentModel = 'conversation'
    },
    afterCalling(){
      this.currentModel = 'message'
    },
    onClickLeft(){
      // this.$router.back(-1)
      this.$router.replace('/message')
    },
    openChat() {
        // 切换会话进入聊天
        TUIConversationService.switchConversation(this.$route.query.chatId)
      },
    onMinimized: function onMinimized(oldStatus: boolean, newStatus: boolean) {
      this.showCallMini = newStatus;
    },
    chatfun(chatId){
      TUIConversationService.switchConversation(chatId);
    },
  },
  mounted: function () {
      TUIStore.watch(StoreName.CONV, {
        currentConversationID: (id: string) => {
          if(sessionStorage.getItem('chatState')){
            sessionStorage.removeItem('chatState');
            this.onClickLeft()
          }
          this.currentConversationID = id;
        },
      });
      
      this.chatfun(this.$route.query.chatId)
     
   
  },
  created() {
    // this.IMSIG()
  },
});
