const TUIProfile = {
  "资料设置": "资料设置",
  "头像": "头像",
  "昵称": "昵称",
  "账号": "账号",
  "个性签名": "个性签名",
  "性别": "性别",
  "男": "男",
  "女": "女",
  "不显示": "不显示",
  "出生年月":"出生年月",
  "取消": "取消",
  "保存": "保存",
  "请选择出生日期": "请选择出生日期",
  "选择头像": "选择头像",
  "设置昵称": "设置昵称",
  "仅限中文、字母、数字和下划线，2-20个字": "仅限中文、字母、数字和下划线，2-20个字",
  "请输入昵称": "请输入昵称",
  "性别选择": "性别选择",
  "请输入内容": "请输入内容",
  "用户ID": "用户ID",
  "暂未设置": "暂未设置"
}

export default TUIProfile;
