
import TUIChatEngine, { TUITranslateService } from "@tencentcloud/chat-uikit-engine";
import { computed } from "../../../../adapter-vue";
import { generateAvatar, generateName, acceptFriendApplication } from "../../utils";
const __sfc_main = {};
__sfc_main.props = {
  listItem: {
    type: Object,
    default: {}
  },
  list: {
    type: Array,
    default: []
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const groupType = {
    [TUIChatEngine.TYPES.GRP_WORK]: "Work",
    [TUIChatEngine.TYPES.GRP_AVCHATROOM]: "AVChatRoom",
    [TUIChatEngine.TYPES.GRP_PUBLIC]: "Public",
    [TUIChatEngine.TYPES.GRP_MEETING]: "Meeting",
    [TUIChatEngine.TYPES.GRP_COMMUNITY]: "Community"
  };
  const otherContentForSow = computed((): string => {
    let content = "";
    if (props?.listItem?.type === TUIChatEngine?.TYPES?.SNS_APPLICATION_SENT_TO_ME || props?.listItem?.type === TUIChatEngine?.TYPES?.SNS_APPLICATION_SENT_BY_ME) {
      content = props?.listItem?.wording || "";
    } else if (props?.listItem?.groupID) {
      content = `ID:${props?.listItem?.groupID}`;
    }
    return content;
  });
  const groupTypeForShow = computed((): string => {
    let type = "";
    if (props?.listItem?.groupID) {
      type = groupType[props?.listItem?.type];
    }
    return type;
  });
  const showApplicationStatus = computed(() => {
    if (props?.listItem?.type === TUIChatEngine?.TYPES?.SNS_APPLICATION_SENT_BY_ME) {
      // 我发出的好友申请
      return {
        style: "text",
        label: "等待验证"
      };
    } else if (props?.listItem?.type === TUIChatEngine?.TYPES?.SNS_APPLICATION_SENT_TO_ME) {
      // 我收到的好友申请
      return {
        style: "button",
        label: "同意",
        onClick: () => {
          acceptFriendApplication(props?.listItem?.userID);
        }
      };
    }
    return false;
  });
  return {
    TUITranslateService,
    generateAvatar,
    generateName,
    props,
    otherContentForSow,
    groupTypeForShow,
    showApplicationStatus
  };
};
export default __sfc_main;
