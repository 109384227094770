var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tui-conversation-list"},[(_vm.isShowOverlay)?_c('ActionsMenu',{attrs:{"selectedConversation":_vm.currentConversation,"actionsMenuPosition":_vm.actionsMenuPosition,"selectedConversationDomRect":_vm.currentConversationDomRect},on:{"closeConversationActionMenu":_vm.closeConversationActionMenu}}):_vm._e(),_vm._l((_vm.conversationList),function(conversation,index){return _c('div',{key:index,class:[
      'TUI-conversation-content',
      !_vm.isPC && 'TUI-conversation-content-h5',
    ],attrs:{"id":`convlistitem-${index}`}},[_c('div',{class:[
        _vm.isPC && 'isPC',
        'TUI-conversation-item',
        _vm.currentConversationID === conversation.conversationID &&
          'TUI-conversation-item-selected',
        conversation.isPinned && 'TUI-conversation-item-pinned',
      ],on:{"click":function($event){return _vm.enterConversationChat(conversation.conversationID)},"longpress":function($event){return _vm.showConversationActionMenu($event, conversation, index)},"contextmenu":function($event){$event.preventDefault();return _vm.showConversationActionMenu($event, conversation, index, true)}}},[_c('aside',{staticClass:"left"},[_c('img',{staticClass:"avatar",attrs:{"src":conversation.getAvatar()}}),(_vm.userOnlineStatusMap && _vm.isShowUserOnlineStatus(conversation))?_c('div',{class:[
            'online-status',
            Object.keys(_vm.userOnlineStatusMap).length > 0 &&
            Object.keys(_vm.userOnlineStatusMap).includes(
              conversation.userProfile.userID
            ) &&
            _vm.userOnlineStatusMap[conversation.userProfile.userID]
              .statusType === 1
              ? 'online-status-online'
              : 'online-status-offline',
          ]}):_vm._e(),(conversation.unreadCount > 0 && !conversation.isMuted)?_c('span',{staticClass:"num"},[_vm._v(" "+_vm._s(conversation.unreadCount > 99 ? "99+" : conversation.unreadCount)+" ")]):_vm._e(),(conversation.unreadCount > 0 && conversation.isMuted)?_c('span',{staticClass:"num-notify"}):_vm._e()]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"content-header"},[_c('label',{staticClass:"content-header-label"},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(conversation.getShowName()))])]),_c('div',{staticClass:"middle-box"},[(
                conversation.type === 'GROUP' &&
                conversation.groupAtInfoList &&
                conversation.groupAtInfoList.length > 0
              )?_c('span',{staticClass:"middle-box-at"},[_vm._v(_vm._s(conversation.getGroupAtInfo()))]):_vm._e(),_c('p',{staticClass:"middle-box-content"},[_vm._v(" "+_vm._s(conversation.getLastMessage("text"))+" ")])])]),_c('div',{staticClass:"content-footer"},[_c('span',{staticClass:"time"},[_vm._v(_vm._s(conversation.getLastMessage("time")))]),(conversation.isMuted)?_c('Icon',{attrs:{"file":_vm.muteIcon}}):_vm._e()],1)])])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }