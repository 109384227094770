
const __sfc_main = {};
__sfc_main.props = {
  width: {
    type: String,
    default: "30px"
  },
  height: {
    type: String,
    default: "30px"
  },
  color: {
    type: String,
    default: "#D9D9D9"
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  return {};
};
export default __sfc_main;
