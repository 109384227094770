
import { ref } from "../../adapter-vue";
import { isApp } from "../../utils/env";
const __sfc_main = {};
__sfc_main.props = {
  file: {
    type: String,
    default: ""
  },
  width: {
    type: String,
    default: "20px"
  },
  height: {
    type: String,
    default: "20px"
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emits = __ctx.emit;
  const props = __props;
  const handlerImgClick = () => {
    emits("click");
  };
  return {
    isApp,
    props,
    handlerImgClick
  };
};
export default __sfc_main;
