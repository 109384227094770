
import { TUITranslateService, IGroupModel } from "@tencentcloud/chat-uikit-engine";
import { watchEffect, ref } from "../../../adapter-vue";
import Slider from "../../common/Slider/index.vue";
import Icon from "../../common/Icon.vue";
import plusSVG from "../../../assets/icon/plus.svg";
import minusSVG from "../../../assets/icon/minus.svg";
import { IGroupMember } from "../../../interface";
const __sfc_main = {};
__sfc_main.props = {
  member: {
    type: Object,
    default: () => {}
  },
  isSetMuteTime: {
    type: Boolean,
    default: () => false
  },
  currentGroup: {
    type: Object,
    default: () => {}
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const isAdminSetMuteTime = ref(false);
  const memberAdmin = ref({
    admin: [] as Array<IGroupMember>,
    member: [] as Array<IGroupMember>,
    muteMember: [] as Array<IGroupMember>
  });
  const currentGroupAdmin = ref<IGroupModel>();
  watchEffect(() => {
    memberAdmin.value = props.member as {
      admin: Array<IGroupMember>;
      member: Array<IGroupMember>;
      muteMember: Array<IGroupMember>;
    };
    isAdminSetMuteTime.value = props.isSetMuteTime;
    currentGroupAdmin.value = props.currentGroup;
  });
  const emits = __ctx.emit;
  const addAdmin = () => {
    emits("addAdmin");
  };
  const removeAdmin = () => {
    emits("removeAdmin");
  };
  const setAllMuteTime = (value: boolean) => {
    emits("setAllMuteTime", value);
  };
  const addMute = () => {
    emits("addMute");
  };
  const removeMute = () => {
    emits("removeMute");
  };
  const close = (tabName: string) => {
    emits("close", tabName);
  };
  return {
    TUITranslateService,
    plusSVG,
    minusSVG,
    isAdminSetMuteTime,
    memberAdmin,
    currentGroupAdmin,
    addAdmin,
    removeAdmin,
    setAllMuteTime,
    addMute,
    removeMute
  };
};
__sfc_main.components = Object.assign({
  Icon,
  Slider
}, __sfc_main.components);
export default __sfc_main;
