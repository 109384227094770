
import type { IMessageModel } from "@tencentcloud/chat-uikit-engine";
import { withDefaults, watch, watchEffect } from "../../../adapter-vue";
import { isUniFrameWork } from "../../../utils/env";
import type { IImageMessageContent } from "../../../interface";
const __sfc_main = {};
__sfc_main.props = {
  content: {
    key: "content",
    required: false,
    type: null,
    default: () => ({})
  },
  messageItem: {
    key: "messageItem",
    required: false,
    type: null,
    default: () => ({}) as IMessageModel
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  return {
    isUniFrameWork,
    props
  };
};
export default __sfc_main;
