var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"image-previewer",class:[_vm.isMobile && 'image-previewer-h5']},[_c('div',{ref:"image",staticClass:"image-wrapper",on:{"touchstart":function($event){$event.stopPropagation();return _vm.handleTouchStart.apply(null, arguments)},"touchmove":function($event){$event.stopPropagation();return _vm.handleTouchMove.apply(null, arguments)},"touchend":function($event){$event.stopPropagation();return _vm.handleTouchEnd.apply(null, arguments)},"touchcancel":function($event){$event.stopPropagation();return _vm.handleTouchCancel.apply(null, arguments)},"wheel":function($event){$event.stopPropagation();return _vm.handleWheel.apply(null, arguments)}}},[_c('ul',{ref:"ulRef",staticClass:"image-list",style:({
        width: `${_vm.imageList.length * 100}%`,
        transform: `translateX(-${
          (_vm.currentImageIndex * 100) / _vm.imageList.length
        }%)`,
        transition: '0.5s',
      })},_vm._l((_vm.imageList),function(item,index){return _c('li',{key:index,staticClass:"image-item"},[_c('ImageItem',{class:[_vm.isUniFrameWork ? 'image-item' : ''],attrs:{"zoom":_vm.zoom,"rotate":_vm.rotate,"src":_vm.getImageUrl(item),"messageItem":item}})],1)}),0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPC),expression:"isPC"}],staticClass:"icon icon-close",on:{"click":_vm.close}},[_c('Icon',{attrs:{"file":_vm.iconClose,"width":"16px","height":"16px"}})],1),(_vm.isPC && _vm.currentImageIndex > 0)?_c('div',{staticClass:"image-button image-button-left",on:{"click":_vm.goPrev}},[_c('Icon',{attrs:{"file":_vm.iconArrowLeft}})],1):_vm._e(),(_vm.isPC && _vm.currentImageIndex < _vm.imageList.length - 1)?_c('div',{staticClass:"image-button image-button-right",on:{"click":_vm.goNext}},[_c('Icon',{attrs:{"file":_vm.iconArrowLeft}})],1):_vm._e(),_c('div',{class:['actions-bar', _vm.isMobile && 'actions-bar-h5']},[(_vm.isPC)?_c('div',{staticClass:"icon-zoom-in",on:{"click":_vm.zoomIn}},[_c('Icon',{attrs:{"file":_vm.iconZoomIn,"width":"27px","height":"27px"}})],1):_vm._e(),(_vm.isPC)?_c('div',{staticClass:"icon-zoom-out",on:{"click":_vm.zoomOut}},[_c('Icon',{attrs:{"file":_vm.iconZoomOut,"width":"27px","height":"27px"}})],1):_vm._e(),(_vm.isPC)?_c('div',{staticClass:"icon-refresh-left",on:{"click":_vm.rotateLeft}},[_c('Icon',{attrs:{"file":_vm.iconRotateLeft,"width":"27px","height":"27px"}})],1):_vm._e(),(_vm.isPC)?_c('div',{staticClass:"icon-refresh-right",on:{"click":_vm.rotateRight}},[_c('Icon',{attrs:{"file":_vm.iconRotateRight,"width":"27px","height":"27px"}})],1):_vm._e(),_c('span',{staticClass:"image-counter"},[_vm._v(" "+_vm._s(_vm.currentImageIndex + 1)+" / "+_vm._s(_vm.imageList.length)+" ")])]),_c('div',{staticClass:"save",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('Icon',{attrs:{"file":_vm.iconDownload,"width":"20px","height":"20px"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }