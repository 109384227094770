
import { TUITranslateService, IGroupModel } from "@tencentcloud/chat-uikit-engine";
import { watchEffect, ref, nextTick } from "../../../adapter-vue";
import Icon from "../../common/Icon.vue";
import editIcon from "../../../assets/icon/edit.svg";
import { Toast, TOAST_TYPE } from "../../common/Toast/index";
import { isPC, isUniFrameWork } from "../../../utils/env";
const __sfc_main = {};
__sfc_main.props = {
  data: {
    type: Object,
    default: () => ({})
  },
  isAuthor: {
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const groupProfile = ref<IGroupModel>({});
  const inputGroupName = ref("");
  const isEdit = ref(false);
  const nameInputRef = ref(null);
  watchEffect(() => {
    groupProfile.value = props.data;
  });
  const emit = __ctx.emit;
  const updateProfile = () => {
    if (!inputGroupName.value) {
      Toast({
        message: "群名称不能为空",
        type: TOAST_TYPE.ERROR
      });
    } else {
      if (inputGroupName.value !== groupProfile.value.name) {
        emit("update", {
          key: "name",
          value: inputGroupName.value
        });
        groupProfile.value.name = inputGroupName.value;
        inputGroupName.value = "";
        Toast({
          message: "群名称修改成功",
          type: TOAST_TYPE.SUCCESS
        });
      }
      close();
    }
  };
  const close = () => {
    if (props.isAuthor) {
      isEdit.value = !isEdit.value;
      // 只有 pc 会有这样的情况
      isPC && nextTick(() => {
        // 点击 dom 外侧更改群组名称并关闭input
        onClickOutside(nameInputRef.value);
      });
    }
    if (isEdit.value) {
      inputGroupName.value = groupProfile.value.name;
    }
  };
  let clickOutside = false;
  let clickInner = false;
  const onClickOutside = (component: any) => {
    if (isUniFrameWork) {
      return;
    }
    document.addEventListener("mousedown", onClickDocument);
    component?.addEventListener && component?.addEventListener("mousedown", onClickTarget);
  };
  const onClickDocument = () => {
    clickOutside = true;
    if (!clickInner && clickOutside) {
      updateProfile();
      removeClickListener(nameInputRef.value);
    }
    clickOutside = false;
    clickInner = false;
  };
  const onClickTarget = () => {
    clickInner = true;
  };
  const removeClickListener = (component: any) => {
    document.removeEventListener("mousedown", onClickDocument);
    component?.removeEventListener && component?.removeEventListener("mousedown", onClickTarget);
  };
  return {
    TUITranslateService,
    editIcon,
    isPC,
    groupProfile,
    inputGroupName,
    isEdit,
    nameInputRef,
    updateProfile,
    close
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
