
import { TUITranslateService } from "@tencentcloud/chat-uikit-engine";
import { ref, computed } from "../../../../../adapter-vue";
import { CHAT_MSG_CUSTOM_TYPE } from "../../../../../constant";
import { JSONToObject, isUrl } from "../../../../../utils/index";
import Icon from "../../../../common/Icon.vue";
import star from "../../../../../assets/icon/star-light.png";
const __sfc_main = {};
__sfc_main.props = {
  contentText: {
    type: Array,
    default: []
  },
  message: {
    type: Object,
    default: () => ({})
  },
  messageContent: {
    type: Object,
    default: () => ({})
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const custom = ref<{
    data?: String;
    description?: String;
    extension?: String;
  }>(props?.message?.payload);
  const extensionJSON = computed(() => JSONToObject(custom?.value?.data));
  const businessID = computed(() => extensionJSON?.value?.businessID);
  const descriptionForShow = ref<Array<{
    text: string;
    isHighlight: boolean;
  }>>(props?.contentText);
  const defaultMessageContent = ref<string>(props?.messageContent?.custom || "[自定义消息]");
  return {
    TUITranslateService,
    CHAT_MSG_CUSTOM_TYPE,
    isUrl,
    star,
    extensionJSON,
    businessID,
    descriptionForShow,
    defaultMessageContent
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
