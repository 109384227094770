
import { TUITranslateService, TUIUserService, TUIConversationService, TUIStore, StoreName } from "@tencentcloud/chat-uikit-engine";
import { ref, watch, watchEffect } from "../../../adapter-vue";
import { IUserProfile } from "../../../interface";
import { isUniFrameWork } from "../../../utils/env";
const __sfc_main = {};
__sfc_main.props = {
  userInfo: {
    type: Object,
    default: () => ({})
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const isFriendShip = ref(false);
  const userInfoManage = ref<IUserProfile>({});
  watchEffect(() => {
    userInfoManage.value = props.userInfo;
  });
  const emits = __ctx.emit;
  watch(() => props.userInfo, async (newVal: any, oldVal: any) => {
    if (newVal === oldVal) return;
    const res = await TUIUserService.getUserProfile({
      userIDList: [props.userInfo.userID]
    });
    userInfoManage.value = res?.data[0];
    // 这里需要确认是否是好友关系
    checkFriend();
  }, {
    deep: true,
    immediate: true
  });
  const enter = async (ID: any, type: string) => {
    const name = `${type}${ID}`;
    TUIConversationService.getConversationProfile(name).then((res: any) => {
      TUIConversationService.switchConversation(res.data.conversation.conversationID);
      if (isUniFrameWork) {
        emits("openConversation");
      } else {
        emits("handleSwitchConversation", res.data.conversation.conversationID);
      }
    }).catch((err: any) => {
      console.warn("获取会话资料失败", err.code, err.msg);
    });
  };
  const checkFriend = async () => {
    if (!(userInfoManage.value as any).userID) return;
    // 这里暂时屏蔽
    // const relation = await TUIFriendService.checkFriend(userInfo.value.userID, TUIChatEngine.TYPES.SNS_CHECK_TYPE_BOTH);
    // isFriendShip.value = (relation === TUIChatEngine.TYPES.SNS_TYPE_BOTH_WAY) ? true : false;
    if (!isUniFrameWork) {
      isFriendShip.value = true;
    }
  };
  const showEnter = () => {
    return isFriendShip.value || TUIStore.getData(StoreName.APP, 'isOfficial');
  };
  const close = (tabName: string) => {
    emits("close", tabName);
  };
  return {
    TUITranslateService,
    isUniFrameWork,
    userInfoManage,
    enter,
    showEnter,
    close
  };
};
export default __sfc_main;
