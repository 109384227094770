
import { watchEffect, ref } from "../../../../adapter-vue";
import Icon from "../../../common/Icon.vue";
import audioIcon from "../../../../assets/icon/msg-audio.svg";
import { isPC } from "../../../../utils/env";
const __sfc_main = {};
__sfc_main.props = {
  content: {
    type: Object,
    default: () => ({})
  },
  messageItem: {
    type: Object,
    default: () => ({})
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const data = ref();
  const message = ref();
  const show = ref();
  const audioRef = ref(null);
  watchEffect(() => {
    message.value = props.messageItem;
    data.value = props.content;
  });
  const play = () => {
    if (message.value.hasRiskContent) {
      return;
    }
    const audios = document.getElementsByTagName("audio");
    for (const audio of audios) {
      if (!audio.paused) {
        audio.pause();
        audio.load();
      }
    }
    const audioPlayer: any = audioRef.value;
    if (audioPlayer.paused) {
      audioPlayer.play();
      show.value = true;
    } else {
      audioPlayer.pause();
      audioPlayer.load();
      show.value = false;
    }
  };
  return {
    audioIcon,
    isPC,
    data,
    message,
    audioRef,
    play
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
