var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"message-video"},[_c('div',{ref:"skeleton",staticClass:"message-video-box",class:[
      (!_vm.props.messageItem.progress || _vm.props.messageItem.progress === 1)
      && !_vm.isPC
      && 'message-video-cover',
    ],on:{"click":_vm.toggleVideoPreviewer}},[(
        (_vm.props.messageItem.progress > 0 && _vm.props.messageItem.progress < 1 && _vm.poster) ||
        (!_vm.isPC && _vm.poster)
      )?_c('img',{staticClass:"message-img",class:[_vm.isWidth ? 'isWidth' : 'isHeight'],attrs:{"src":_vm.poster}}):(!_vm.isPC)?_c('video',{ref:"videoRef",staticClass:"message-img video-h5-uploading",attrs:{"src":_vm.props.content.url + '#t=0.1',"poster":_vm.props.content.url,"preload":"auto","muted":""},domProps:{"muted":true}}):_c('video',{ref:"videoRef",staticClass:"message-img video-web",attrs:{"src":_vm.props.content.url,"controls":"","preload":"metadata","poster":_vm.poster}})]),(_vm.isShow && !_vm.isPC)?_c('div',{staticClass:"dialog-video"},[_c('div',{staticClass:"dialog-video-close",on:{"click":function($event){$event.stopPropagation();return _vm.toggleVideoPreviewer.apply(null, arguments)}}},[_c('Icon',{attrs:{"file":_vm.closeSVG}})],1),_c('div',{staticClass:"dialog-video-box",class:[!_vm.isPC ? 'dialog-video-h5' : ''],on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.toggleVideoPreviewer.apply(null, arguments)}}},[_c('video',{class:[_vm.isWidth ? 'isWidth' : 'isHeight'],attrs:{"src":_vm.props.content.url,"controls":"","autoplay":""}})])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }