
import { ref, watchEffect, computed } from "../../../adapter-vue";
import { TUITranslateService } from "@tencentcloud/chat-uikit-engine";
import { ITransferListItem } from "../../../interface";
import Icon from "../Icon.vue";
import selectedIcon from "../../../assets/icon/selected.svg";
import backIcon from "../../../assets/icon/back.svg";
import cancelIcon from "../../../assets/icon/cancel.svg";
import { isPC, isWeChat, isUniFrameWork } from "../../../utils/env";
const __sfc_main = {};
__sfc_main.props = {
  list: {
    type: Array,
    default: () => []
  },
  selectedList: {
    type: Array,
    default: () => []
  },
  isSearch: {
    type: Boolean,
    default: true
  },
  isRadio: {
    type: Boolean,
    default: false
  },
  isCustomItem: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ""
  },
  type: {
    type: String,
    default: ""
  },
  resultShow: {
    type: Boolean,
    default: true
  },
  total: {
    type: Number,
    default: 0
  },
  isHiddenBackIcon: {
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const type = ref("");
  const transferList = ref<Array<ITransferListItem>>([]);
  const transferTotal = ref<Number>(0);
  const transferSelectedList = ref<Array<ITransferListItem>>([]);
  const isTransferSearch = ref(true);
  const isTransferCustomItem = ref(false);
  const transferTitle = ref("");
  const searchValue = ref("");
  watchEffect(() => {
    if (props.isCustomItem) {
      for (let index = 0; index < props.list.length; index++) {
        if ((props.list[index] as any).conversationID.indexOf("@TIM#SYSTEM") > -1) {
          props.list.splice(index, 1);
        }
        transferList.value = props.list as Array<ITransferListItem>;
      }
    } else {
      transferList.value = props.list as Array<ITransferListItem>;
    }
    transferTotal.value = props.total ? props.total : props.list.length;
    transferSelectedList.value = (props.selectedList && props.selectedList.length > 0 ? props.selectedList : transferSelectedList.value) as any;
    isTransferSearch.value = props.isSearch;
    isTransferCustomItem.value = props.isCustomItem;
    transferTitle.value = props.title;
    type.value = props.type;
  });
  const emit = __ctx.emit;

  // 可选项
  const optional = computed(() => transferList.value.filter((item: any) => !item.isDisabled));
  const handleInput = (e: any) => {
    searchValue.value = e.target.value;
    emit("search", e.target.value);
  };
  const selected = (item: any) => {
    if (item.isDisabled) {
      return;
    }
    let list: Array<ITransferListItem> = transferSelectedList.value;
    const index: number = list.indexOf(item);
    if (index > -1) {
      return transferSelectedList.value.splice(index, 1);
    }
    if (props.isRadio) {
      list = [];
    }
    list.push(item);
    transferSelectedList.value = list;
  };
  const selectedAll = () => {
    if (transferSelectedList.value.length === optional.value.length) {
      transferSelectedList.value = [];
    } else {
      transferSelectedList.value = [...optional.value];
    }
  };
  const submit = () => {
    emit("submit", transferSelectedList.value);
    // 针对小程序做的数据清空
    searchValue.value = "";
  };
  const cancel = () => {
    emit("cancel");
    // 针对小程序做的数据清空
    searchValue.value = "";
  };
  const getMore = () => {
    emit("getMore");
  };
  return {
    TUITranslateService,
    selectedIcon,
    backIcon,
    cancelIcon,
    isPC,
    isWeChat,
    isUniFrameWork,
    props,
    transferList,
    transferTotal,
    transferSelectedList,
    isTransferSearch,
    isTransferCustomItem,
    transferTitle,
    searchValue,
    optional,
    handleInput,
    selected,
    selectedAll,
    submit,
    cancel,
    getMore
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
