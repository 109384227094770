
interface IUserStatus {
  statusType: number;
  customStatus: string;
}
interface IUserStatusMap {
  [userID: string]: IUserStatus;
}
import { ref } from "../../../adapter-vue";
import TUIChatEngine, { TUIStore, StoreName, TUIConversationService } from "@tencentcloud/chat-uikit-engine";
import { IConversationModel } from "@tencentcloud/chat-uikit-engine";
import Icon from "../../common/Icon.vue";
import ActionsMenu from "../actions-menu/index.vue";
import muteIcon from "../../../assets/icon/mute.svg";
import { isPC, isH5, isUniFrameWork } from "../../../utils/env";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emits = __ctx.emit;
  const currentConversation = ref<IConversationModel>();
  const currentConversationID = ref<string>();
  const currentConversationDomRect = ref<DOMRect>();
  const isShowOverlay = ref<boolean>(false);
  const conversationList = ref<IConversationModel[]>([]);
  const conversationListDomRef = ref<HTMLElement | undefined>();
  const actionsMenuPosition = ref<{
    top: number;
    left?: number;
    conversationHeight?: number;
  }>({
    top: 0
  });
  const displayOnlineStatus = ref(false); // 在线状态 默认关闭
  const userOnlineStatusMap = ref<IUserStatusMap>();
  let lastestOpenActionsMenuTime: number | null = null;
  TUIStore.watch(StoreName.CONV, {
    currentConversationID: (id: string) => {
      currentConversationID.value = id;
    },
    conversationList: (list: Array<IConversationModel>) => {
      conversationList.value = list;
    },
    currentConversation: (conversation: IConversationModel) => {
      currentConversation.value = conversation;
    }
  });

  // 初始状态
  TUIStore.watch(StoreName.USER, {
    displayOnlineStatus: (status: boolean) => {
      displayOnlineStatus.value = status;
    },
    userStatusList: (list: Map<string, IUserStatus>) => {
      if (list.size !== 0) {
        userOnlineStatusMap.value = [...list.entries()].reduce((obj, [key, value]) => {
          obj[key] = value;
          return obj;
        }, {} as IUserStatusMap);
      }
    }
  });
  const isShowUserOnlineStatus = (conversation: IConversationModel): boolean => {
    return displayOnlineStatus.value && conversation.type === TUIChatEngine.TYPES.CONV_C2C;
  };
  const showConversationActionMenu = (event: Event, conversation: IConversationModel, index: number, isContextMenuEvent?: boolean) => {
    if (isContextMenuEvent && isUniFrameWork) {
      return;
    }
    currentConversation.value = conversation;
    lastestOpenActionsMenuTime = Date.now();
    getActionsMenuPosition(event, index);
  };
  const closeConversationActionMenu = () => {
    // 防止连续触发overlay的tap事件
    if (lastestOpenActionsMenuTime && Date.now() - lastestOpenActionsMenuTime > 300) {
      currentConversation.value = undefined;
      isShowOverlay.value = false;
    }
  };
  const getActionsMenuPosition = (event: Event, index: number, conversation?: any) => {
    if (isUniFrameWork) {
      if (typeof conversationListDomRef.value === 'undefined') {
        emits('getPassingRef', conversationListDomRef);
      }
      const query = uni.createSelectorQuery().in(conversationListDomRef.value);
      query.select(`#convlistitem-${index}`).boundingClientRect(data => {
        if (data) {
          actionsMenuPosition.value = {
            // uni-h5的uni-page-head不被认为是视窗中的成员，因此手动上head的高度
            top: data.bottom + (isH5 ? 44 : 0),
            left: event.touches[0].pageX,
            conversationHeight: data.height
          };
          isShowOverlay.value = true;
        }
      }).exec();
    } else {
      // 处理Vue原生
      const rect = event.currentTarget?.getBoundingClientRect();
      if (rect) {
        actionsMenuPosition.value = {
          top: rect.bottom,
          left: isPC ? event.clientX : undefined,
          conversationHeight: rect.height
        };
      }
      isShowOverlay.value = true;
    }
  };
  const enterConversationChat = (conversationID: string) => {
    emits("handleSwitchConversation", conversationID);
    TUIConversationService.switchConversation(conversationID);
  };

  // 暴露给父组件，当监听到滑动事件时关闭actionsMenu
  return Object.assign({
    muteIcon,
    isPC,
    currentConversation,
    currentConversationID,
    currentConversationDomRect,
    isShowOverlay,
    conversationList,
    actionsMenuPosition,
    userOnlineStatusMap,
    isShowUserOnlineStatus,
    showConversationActionMenu,
    closeConversationActionMenu,
    enterConversationChat
  }, {
    closeChildren: closeConversationActionMenu
  });
};
__sfc_main.components = Object.assign({
  ActionsMenu,
  Icon
}, __sfc_main.components);
export default __sfc_main;
