
import { ref, computed } from "../../../adapter-vue";
import TUIChatEngine, { IConversationModel, TUIStore, StoreName } from "@tencentcloud/chat-uikit-engine";
import ElementUI from 'element-ui';
import TUICore, { ExtensionInfo, TUIConstants } from "@tencentcloud/tui-core";
import EmojiPicker from "./emoji-picker/index.vue";
import ImageUpload from "./image-upload/index.vue";
import FileUpload from "./file-upload/index.vue";
import VideoUpload from "./video-upload/index.vue";
import Evaluate from "./evaluate/index.vue";
import Words from "./words/index.vue";
import ToolbarItemContainer from "./toolbar-item-container/index.vue";
import UserSelector from "./user-selector/index.vue";
import { Toast, TOAST_TYPE } from "../../common/Toast/index";
import { isPC, isH5, isApp, isUniFrameWork } from "../../../utils/env";
import { TUIChatService } from "@tencentcloud/chat-uikit-engine";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  let that = this;
  const emits = __ctx.emit;
  const h5Dialog = ref();
  const currentConversation = ref<IConversationModel>();
  const isGroup = ref<boolean>(false);
  const selectorShowType = ref<string>("");
  const userSelectorRef = ref();
  const currentUserSelectorExtension = ref<ExtensionInfo>();
  TUIStore.watch(StoreName.CONV, {
    currentConversation: (conversation: IConversationModel) => {
      currentConversation.value = conversation;
      if (currentConversation?.value?.type === TUIChatEngine.TYPES.CONV_GROUP) {
        isGroup.value = true;
      } else {
        isGroup.value = false;
      }
    }
  });
  function urge() {
    let promise = TUIChatService.sendCustomMessage({
      payload: {
        data: JSON.stringify({
          type: 9,
          data: JSON.stringify({
            orderCode: this.$route.query.pres_id,
            userName: this.$route.query.userName,
            doctorName: this.$route.query.doctorName
          })
        })
      }
    });
    promise.catch(error => {
      // 调用异常时业务侧可以通过 promise.catch 捕获异常进行错误处理
    });
  }

  // extensions
  const extensionList: Array<ExtensionInfo> = [...TUICore.getExtensionList(TUIConstants.TUIChat.EXTENSION.INPUT_MORE.EXT_ID)];

  // 按展示位置分类 extensionList （注意：仅 web 端 区分展示位置在 从 start 开始和 从 end 开始，在移动端不生效）
  const extensionListShowInStart = computed((): Array<ExtensionInfo> => isPC ? extensionList.filter((extension: ExtensionInfo) => extension?.data?.name !== "search") : extensionList);
  // const Communicate = ()=>{
  //   // const urlParams = new URLSearchParams(window.location.search);
  //   // console.log(urlParams.get('chatId'))
  //   // console.log(urlParams.get('pres_id'))
  //   console.log(window.location.hash)
  //   const hashParams = window.location.hash

  // // 解析哈希参数
  // const params = {};
  // hashParams
  //   .substring(1) // 去掉开头的 "#"
  //   .split('&') // 分割参数
  //   .forEach(param => {
  //     const [key, value] = param.split('=');
  //     params[key] = decodeURIComponent(value);
  //   });

  // // 获取特定参数的值
  //   const doctorId = params['doctorId']; // 输出 "C2C1398414924382209"
  //   const presId = params['pres_id']; // 输出 "1401245457186816"

  //   let data = {
  //     name:doctorId,
  //     pres_id:presId
  //   }
  //   api.article.AccelerationPres('chat',data).then(res=>{
  //     console.log(ElementUI)
  //     ElementUI.Message({
  //       message: '操作成功',
  //       type: 'success'
  //     });
  //   })
  // }
  const extensionListShowInEnd = computed((): Array<ExtensionInfo> => isPC ? [extensionList.find((extension: ExtensionInfo) => extension?.data?.name === "search")] : []);

  // handle extensions onclick
  const onExtensionClick = (extension: ExtensionInfo) => {
    switch (extension?.data?.name) {
      case "voiceCall":
        onCallExtensionClicked(extension, 1);
        break;
      case "videoCall":
        onCallExtensionClicked(extension, 2);
        break;
      case "search":
        extension?.listener?.onClicked();
        break;
      default:
        break;
    }
  };
  const onCallExtensionClicked = (extension: ExtensionInfo, callType: number) => {
    selectorShowType.value = extension?.data?.name;
    if (currentConversation?.value?.type === TUIChatEngine.TYPES.CONV_C2C) {
      extension?.listener?.onClicked({
        userIDList: [currentConversation?.value?.conversationID?.slice(3)],
        type: callType
      });
    } else if (isGroup.value) {
      currentUserSelectorExtension.value = extension;
      userSelectorRef?.value?.toggleShow && userSelectorRef.value.toggleShow(true);
    }
  };
  const genExtensionIcon = (extension: any) => {
    return extension?.icon;
  };
  const genExtensionText = (extension: any) => {
    return extension?.text;
  };
  const onUserSelectorSubmit = (selectedInfo: any) => {
    currentUserSelectorExtension.value?.listener?.onClicked(selectedInfo);
    currentUserSelectorExtension.value = null;
  };
  const onUserSelectorCancel = () => {
    currentUserSelectorExtension.value = null;
  };
  const insertEmoji = (emojiObj: object) => {
    emits("insertEmoji", emojiObj);
  };
  const dialogShowInH5 = (dialogDom: any) => {
    if (!isH5) {
      return;
    }
    h5Dialog?.value?.appendChild && h5Dialog?.value?.appendChild(dialogDom);
  };
  const dialogCloseInH5 = (dialogDom: any) => {
    if (!isH5) {
      return;
    }
    h5Dialog?.value?.removeChild && h5Dialog?.value?.removeChild(dialogDom);
  };
  return {
    isPC,
    isH5,
    isUniFrameWork,
    h5Dialog,
    currentConversation,
    isGroup,
    selectorShowType,
    userSelectorRef,
    urge,
    extensionListShowInEnd,
    onExtensionClick,
    genExtensionIcon,
    genExtensionText,
    onUserSelectorSubmit,
    onUserSelectorCancel,
    insertEmoji,
    dialogShowInH5,
    dialogCloseInH5
  };
};
__sfc_main.components = Object.assign({
  ImageUpload,
  FileUpload,
  EmojiPicker,
  ToolbarItemContainer,
  UserSelector
}, __sfc_main.components);
export default __sfc_main;
