
import { ref, watch, computed, nextTick, watchEffect, withDefaults } from "../../../../adapter-vue";
import { IMessageModel } from "@tencentcloud/chat-uikit-engine";
import { handleSkeletonSize } from "../../utils/utils";
import Icon from "../../../common/Icon.vue";
import closeSVG from "../../../../assets/icon/icon-close.svg";
import type { IVideoMessageContent } from "../../../../interface";
import { isPC } from "../../../../utils/env";
const __sfc_main = {};
__sfc_main.props = {
  content: {
    key: "content",
    required: false,
    type: null,
    default: () => ({}) as IVideoMessageContent
  },
  messageItem: {
    key: "messageItem",
    required: false,
    type: null,
    default: () => ({}) as IMessageModel
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emits = __ctx.emit;
  const isShow = ref(false);
  const poster = ref("");
  const posterWidth = ref(0);
  const posterHeight = ref(0);
  const skeleton = ref();
  const videoRef = ref();
  const transparentPosterUrl = "https://web.sdk.qcloud.com/im/assets/images/transparent.png";
  watchEffect(async () => {
    if (!props.content) return;
    poster.value = await handlePosterUrl(props.content, props.messageItem);
    nextTick(async () => {
      const containerWidth = document.getElementById("messageScrollList")?.clientWidth || 0;
      const max = !isPC ? Math.min(containerWidth - 172, 300) : 300;
      let size;
      if (props.messageItem.status === "success") {
        let {
          snapshotWidth = 0,
          snapshotHeight = 0,
          snapshotUrl
        } = props.content;
        if (snapshotWidth === 0 || snapshotHeight === 0) return;
        if (snapshotUrl === transparentPosterUrl) {
          snapshotWidth = posterWidth.value;
          snapshotHeight = posterHeight.value;
        }
        size = handleSkeletonSize(snapshotWidth, snapshotHeight, max, max);
        skeleton?.value?.style && (skeleton.value.style.width = `${size.width}px`);
        skeleton?.value?.style && (skeleton.value.style.height = `${size.height}px`);
        if (isPC) {
          videoRef?.value?.style && (videoRef.value.style.width = `${size.width}px`);
          videoRef?.value?.style && (videoRef.value.style.height = `${size.height}px`);
        }
      } else {
        emits("uploading");
      }
    });
  });
  const isWidth = computed(() => {
    const {
      snapshotWidth = 0,
      snapshotHeight = 0
    } = props.messageItem.payload;
    return snapshotWidth >= snapshotHeight;
  });
  watch(() => props.messageItem.status, (newVal: string, oldVal: string) => {
    if (newVal === "success" && oldVal !== "success") {
      emits("uploading");
    }
  });
  function toggleVideoPreviewer() {
    // 视频上传过程中不支持全屏播放
    if (props.messageItem.progress > 0 && props.messageItem.progress < 1) {
      return;
    }
    isShow.value = !isShow.value;
  }

  // h5 部分浏览器（safari / wx）video标签 封面为空 在视频未上传完成前的封面展示需要单独进行处理截取
  function getVideoBase64(url: string) {
    return new Promise(resolve => {
      let dataURL = "";
      let video = document.createElement("video");
      video.setAttribute("crossOrigin", "anonymous"); //处理跨域
      video.setAttribute("src", url);
      video.setAttribute("preload", "auto");
      video.addEventListener("loadeddata", function () {
        let canvas = document.createElement("canvas"),
          width = video.videoWidth,
          //canvas的尺寸和图片一样
          height = video.videoHeight;
        canvas.width = width;
        canvas.height = height;
        (canvas as any).getContext("2d").drawImage(video, 0, 0, width, height); //绘制canvas
        dataURL = canvas.toDataURL("image/jpeg"); //转换为base64
        posterWidth.value = width;
        posterHeight.value = height;
        resolve(dataURL);
      }, {
        once: true
      });
    });
  }
  async function handlePosterUrl(messgeContent: IVideoMessageContent, messageItem: IMessageModel) {
    if (!messageItem) return "";
    if (messageItem.status !== "success") {
      return await getVideoBase64(messgeContent.url);
    } else {
      return messgeContent.snapshotUrl !== transparentPosterUrl && messgeContent.snapshotUrl || messageItem?.payload?.snapshotUrl !== transparentPosterUrl && messageItem?.payload?.snapshotUrl || messageItem.payload?.thumbUrl !== transparentPosterUrl && messageItem?.payload?.thumbUrl || (await getVideoBase64(messgeContent.url));
    }
  }
  return {
    closeSVG,
    isPC,
    props,
    isShow,
    poster,
    skeleton,
    videoRef,
    isWidth,
    toggleVideoPreviewer
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
