
import { ref, computed } from "../../../../../adapter-vue";
const __sfc_main = {};
__sfc_main.props = {
  contentText: {
    type: Array,
    default: []
  },
  messageContent: {
    type: Object,
    default: () => ({})
  },
  displayType: {
    type: String,
    default: "bubble" // 两种展示形式: "bubble":气泡内文件消息展示 "file":搜索文件列表文件消息展示
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const contentText = ref<Array<{
    text: string;
    isHighlight: boolean;
  }>>(props.contentText);
  const typeIcon = computed(() => {
    const fileUrl = props?.messageContent?.url;
    const index = fileUrl?.lastIndexOf(".");
    const type = fileUrl?.substring(index + 1);
    return handleFileIconForShow(type);
  });
  const fileSize = computed(() => props?.messageContent?.size);
  const handleFileIconForShow = (type: string) => {
    const urlBase = "https://web.sdk.qcloud.com/component/TUIKit/assets/file-";
    const fileTypes = ["image", "pdf", "text", "ppt", "presentation", "sheet", "zip", "word", "video", "unknown"];
    let url = "";
    let iconType = "";
    fileTypes?.forEach((typeName: string) => {
      if (type?.includes(typeName)) {
        url = urlBase + typeName + ".svg";
        iconType = typeName;
      }
    });
    return {
      iconSrc: url ? url : urlBase + "unknown.svg",
      iconType: iconType ? iconType : "unknown"
    };
  };
  return {
    contentText,
    typeIcon,
    fileSize
  };
};
export default __sfc_main;
