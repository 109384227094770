
import TUICore, { TUIConstants } from "@tencentcloud/tui-core";
import TUIChatEngine from "@tencentcloud/chat-uikit-engine";
import { computed, ref } from "../../../adapter-vue";
import { JSONToObject } from "../../../utils/index";
import Icon from "../../../components/common/Icon.vue";
import callVideoSVG from "../../../assets/icon/call-video.svg";
import callVoiceSVG from "../../../assets/icon/call-voice.svg";
const __sfc_main = {};
__sfc_main.props = {
  message: {
    type: Object,
    default: () => ({})
  },
  signalingInfo: {
    type: Object,
    default: () => ({})
  },
  customContent: {
    type: Object,
    default: () => ({})
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const TYPES = ref(TUIChatEngine.TYPES);
  const isCallMessage = computed(() => props.signalingInfo != null);
  const callInfo = computed((): {
    type: number;
    icon: string;
  } => {
    const callType = JSONToObject(props.signalingInfo?.data)?.call_type;
    switch (callType) {
      case 1:
        return {
          type: 1,
          icon: callVoiceSVG
        };
      case 2:
        return {
          type: 2,
          icon: callVideoSVG
        };
      default:
        break;
    }
    return {
      type: 0,
      icon: ""
    };
  });
  const conversationType = computed(() => props.message?.conversationType);
  const custom = computed(() => props.customContent?.custom);
  const callAgain = () => {
    if (conversationType.value === TUIChatEngine.TYPES.CONV_C2C) {
      const userID = props.message?.flow === "out" ? props.message?.to : props.message?.from;
      TUICore.callService({
        serviceName: TUIConstants.TUICalling.SERVICE.NAME,
        method: TUIConstants.TUICalling.SERVICE.METHOD.START_CALL,
        params: {
          userIDList: [userID],
          type: callInfo?.value?.type
        }
      });
    }
  };
  return {
    TYPES,
    isCallMessage,
    callInfo,
    conversationType,
    custom,
    callAgain
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
