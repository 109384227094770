<template>
    <div class="yyxz margin_top bpd barCode" style="height: auto;">
      <video ref="video" id="video" class="video vjs-fluid" autoplay></video>
        <div v-show="tipShow" class="tip">{{tipMsg}}<i v-if="tipMsg == '正在识别条形码'" class="el-icon-loading"></i></div>
        
        <div class="jjxx  margin_top ">
            <van-button color="#02d4d8" @click="barCode_show" block>关 闭</van-button>
        </div>
    </div>
    
</template>
<script>
  // import { MessageBox } from 'mint-ui'
  import { BrowserMultiFormatReader } from '@zxing/library'
  export default {
      name: 'qr-code-search',
      components: {},
      data() {
      return {
          loadingShow: false,
          codeReader: new BrowserMultiFormatReader(),
          textContent: null,
          vin: null,
          tipMsg: '正在尝试识别....',
          tipShow: false,
          pharmacy_id:'',
          barCode:'',
          Drugs:[]
      }
      },
      created() {
        this.pharmacy_id = sessionStorage.getItem('pharmacy_id');
        this.openScan()
      },
      methods: {
        async openScan() {
            const that = this
            that.codeReader.getVideoInputDevices().then((videoInputDevices) => {
            that.tipShow = true
            that.tipMsg = '正在调用后置摄像头...'
            // 默认获取第一个摄像头设备id
            if(videoInputDevices.length == 0){
              this.$message.error('暂未获取到摄像头信息');
              return
            }
            let firstDeviceId = videoInputDevices[0].deviceId;
            // 获取第一个摄像头设备的名称
            const videoInputDeviceslablestr = JSON.stringify(videoInputDevices[0].label);
            if (videoInputDevices.length > 1) {
                // 判断是否后置摄像头
                if (videoInputDeviceslablestr.indexOf('back') > -1) {
                firstDeviceId = videoInputDevices[0].deviceId;
                } else {
                firstDeviceId = videoInputDevices[1].deviceId;
                }
            }
            that.decodeFromInputVideoFunc(firstDeviceId)
            }).catch((err) => {
            that.tipShow = false
            });
        },
        async openScanTwo() {
            const that = this
            // codeReader.reset() // 重置
            // that.textContent = null // 重置
            that.codeReader = await new BrowserMultiFormatReader()
            that.codeReader.getVideoInputDevices().then((videoInputDevices) => {
            that.tipShow = true
            that.tipMsg = '正在调用后置摄像头...'
            // 默认获取第一个摄像头设备id
            let firstDeviceId = videoInputDevices[0].deviceId;
            // 获取第一个摄像头设备的名称
            const videoInputDeviceslablestr = JSON.stringify(videoInputDevices[0].label);
            if (videoInputDevices.length > 1) {
                // 判断是否后置摄像头
                if (videoInputDeviceslablestr.indexOf('back') > -1) {
                firstDeviceId = videoInputDevices[0].deviceId;
                } else {
                firstDeviceId = videoInputDevices[1].deviceId;
                }
            }
            that.decodeFromInputVideoFunc(firstDeviceId)
            }).catch((err) => {
            that.tipShow = false
            });
        },
        decodeFromInputVideoFunc(firstDeviceId) {
            const that = this
            that.codeReader.reset() // 重置
            that.textContent = null // 重置
            that.codeReader.decodeFromInputVideoDeviceContinuously(firstDeviceId, 'video', (result, err) => {
            that.tipMsg = '正在识别条形码'
            // let nowContent = null
            that.textContent = null
            if (result) {
                that.textContent = result;
                // if (that.textContent) {
                // that.tipShow = false
                that.msgBoxFunc(that.textContent)
                // }
            }
            if (err && !(err)) {
                that.tipMsg = '识别失败'
                setTimeout(() => {
                that.tipShow = false
                },2000)
            }
            });
        },
        barCode_show(){
          this.$parent.barCode_show_fun();
        },
        msgBoxFunc(textContent) {
            if(this.barCode == textContent.text){
              return
            }
            this.barCode = textContent.text
          let params = {
              search_db:'Drugs',
              query_type:'first',
            }
            let data = {
              query:[
                {
                    fild:{pharmacy_id:this.pharmacy_id,barCode:textContent.text},
                    type:'=',
                    condition:'&&'
                }
              ],
              tables_name:[
                {
                  name:'PublicDrugs',
                  table_name:'PublicDrugs',
                  my_fild:{id:'publicdrugs_id'},
                  return_table_field:[]
                }
              ]
            }
          this.$api.article.query2(params,data).then(res=>{
            let that = this
            if(res.data.result.id){
                this.$parent.barCode(textContent.text);
                that.codeReader.reset() // 重置
                that.textContent = null // 重置
                this.barCode_show()  
               
            }else{
                this.tipMsg = '暂未查到此条形码对应药品'
            }
          })
        },

      }
  }
</script>
<style scopde lang="less">
 .video{
  width: 100%;
  height: 20rem;  
  margin-top: .5rem;
}
.barCode .tip{
  color: #000;
  font-size: 16px;
  text-align: center;
}
/* common */
.bgc-f4f4f4{
  background-color: #363636;
}
.page{
  overflow-y: auto;
  position: relative;
}

</style>