import axios from 'axios';
import router from '../router/index';
import { Message,Loading } from "element-ui";
    let loading;
    function showLoading() {
        // loading = Loading.service({
        //     lock: true,
        //     text: '拼命加载中...',
        //     background: 'rgba(255,255,255,0.9)',
        // })
    }
    function hideLoading() {
        // toHideLoading();
        // loading.close();
    }
    //防抖：将 300ms 间隔内的关闭 loading 便合并为一次。防止连续请求时， loading闪烁的问题。
    // var toHideLoading = _.debounce(()=>{
    //     if(loading.close) loading.close();
    // }, 300);
    //请求拦截器
    axios.interceptors.request.use(function (config) {
        config.url = localStorage.getItem('userrole_id') ? config.url.indexOf('?') == -1 ?  `${ config.url}?userrole_id=${localStorage.getItem('userrole_id')}` : `${ config.url}&userrole_id=${localStorage.getItem('userrole_id')}` : config.url
        if(config.url.indexOf('ih_type') == -1){
            config.url = localStorage.getItem('ih_type') ? config.url.indexOf('?') == -1 ?  `${ config.url}?ih_type=${localStorage.getItem('ih_type')}` : `${ config.url}&ih_type=${localStorage.getItem('ih_type')}` : config.url
        }
        config.headers['X-Auth-Token'] = localStorage.getItem('userinfo') ?  JSON.parse(localStorage.getItem('userinfo')).token :''
        // 在发送请求之前做些什么
        if(!(config.url.indexOf('/smstoken') == -1 || config.url.indexOf('/sms/') == -1 || config.url.indexOf('/token') == -1) &&  !localStorage.getItem('userinfo')){
            Message({message: '请登录后进行操作',type: 'success'})
            return 
        }
        showLoading();
        return config;
      }, function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    });
    //响应拦截器
    axios.interceptors.response.use(success => {
        // 业务逻辑错误
        hideLoading()
        if(success.data.code < '200' || success.data.code > '200'){
            Message.error({message: success.data.text})
            console.log(success)
            // return ;
        }
        return success
    }, error => {
        hideLoading()
        if (error.response.status == 504 || error.response.status == 404) {
            Message.error({message: '服务器找不到了'})
        } else if(error.response.status == 403) {
            Message.error({message: '权限不足，请联系管理员'})
        } else if(error.response.status == 401) {
            Message.error({message: '请重新登陆'})
            router.replace("/login")
        } else {
            if (error.response.data.message) {
                Message.error({message: error.response.data.message})
            } else {
                Message.error({message: "未知错误,请重试"})
            }
        }
        return;
    })

    let base = ''

    export const postRequest = (url, params)=>{
        return axios({
            method: 'post',
            url: `${base}${url}`,
            data: params
        })
    }

    export default axios