const TUISearch = {
  全部: "全部",
  联系人: "联系人",
  群聊: "群聊",
  聊天记录: "聊天记录",
  文本: "文本",
  文件: "文件",
  "图片/视频": "图片/视频",
  其他: "其他",
  选择时间: "选择时间",
  今天: "今天",
  近三天: "近三天",
  近七天: "近七天",
  查看更多: "查看更多",
  条相关: "条相关",
  条与: "条与",
  相关的: "相关的",
  进入聊天: "进入聊天",
  定位到聊天位置: "定位到聊天位置",
  搜索会话内容: "搜索会话内容",
  查看更多历史记录: "查看更多历史记录",
  暂无搜索结果: "暂无搜索结果",
  结果: "结果",
};

export default TUISearch;
