const TUIConversation = {
  "发起会话": "发起会话",
  "发起单聊": "发起单聊",
  "发起群聊": "发起群聊",
  "请输入userID": "请输入userID",
  "删除会话": "删除会话",
  "删除后，将清空该聊天的消息记录": "删除后，将清空该聊天的消息记录",
  "删除后，将清空该群聊的消息记录": "删除后，将清空该群聊的消息记录",
  "置顶会话": "置顶会话",
  "取消置顶": "取消置顶",
  "消息免打扰": "消息免打扰",
  "取消免打扰": "取消免打扰",
  "系统通知": "系统通知",
  "有人@我": "有人@我",
  "@所有人": "@所有人",
  "条": "条",
  "我": "我",
  "网络异常，请您检查网络设置": "网络异常，请您检查网络设置",
  "您已被群管理员移出群聊": "您已被群管理员移出群聊",
  "该群聊已被解散": "该群聊已被解散",
  "您已退出该群聊": "您已退出该群聊",
}

export default TUIConversation;
