
import { computed, toRefs } from "../../../../adapter-vue";
import TUIChatEngine, { TUITranslateService, TUIGlobal, IMessageModel } from "@tencentcloud/chat-uikit-engine";
import Icon from "../../../common/Icon.vue";
import MessageQuote from "./message-quote/index.vue";
import loadingIcon from "../../../../assets/icon/loading.png";
interface IProps {
  messageItem: IMessageModel;
  content?: any;
  blinkMessageIDList?: string[];
}
interface IEmits {
  (e: "resendMessage"): void;
  (e: "blinkMessage", messageID: string): void;
  // 下面的方法是 uniapp 专属
  (e: "scrollTo", scrollHeight: number): void;
}
const __sfc_main = {};
__sfc_main.props = {
  messageItem: {
    key: "messageItem",
    required: false,
    type: null,
    default: () => ({}) as IMessageModel
  },
  content: {
    key: "content",
    required: false,
    type: null,
    default: () => ({})
  },
  blinkMessageIDList: {
    key: "blinkMessageIDList",
    required: false,
    type: Array,
    default: () => []
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emits = __ctx.emit;
  const props = __props;
  const isPC = TUIGlobal.getPlatform() === "pc";
  const TYPES = TUIChatEngine.TYPES;
  const riskImageReplaceUrl = "https://web.sdk.qcloud.com/component/TUIKit/assets/has_risk_default.png";
  const needLoadingIconMessageType = [TYPES.MSG_LOCATION, TYPES.MSG_TEXT, TYPES.MSG_CUSTOM, TYPES.MSG_MERGER, TYPES.MSG_FACE];
  const {
    blinkMessageIDList,
    messageItem: message
  } = toRefs(props);
  const isNoPadding = computed(() => {
    return [TYPES.MSG_IMAGE, TYPES.MSG_VIDEO].includes(message.value.type);
  });
  const riskContentText = computed<string>(() => {
    let content = TUITranslateService.t("TUIChat.涉及敏感内容") + ", ";
    if (message.value.flow === "out") {
      content += TUITranslateService.t("TUIChat.发送失败");
    } else {
      content += TUITranslateService.t(message.value.type === TYPES.MSG_AUDIO ? "TUIChat.无法收听" : "TUIChat.无法查看");
    }
    return content;
  });
  const isBlink = computed(() => {
    if (message.value?.ID) {
      return blinkMessageIDList?.value?.includes(message.value.ID);
    }
    return false;
  });
  function resendMessage() {
    if (!message.value?.hasRiskContent) {
      emits("resendMessage");
    }
  }
  function blinkMessage(messageID: string) {
    emits("blinkMessage", messageID);
  }
  function scrollTo(scrollHeight: number) {
    emits("scrollTo", scrollHeight);
  }
  console.log(message.value);
  return {
    loadingIcon,
    props,
    isPC,
    TYPES,
    riskImageReplaceUrl,
    needLoadingIconMessageType,
    message,
    isNoPadding,
    riskContentText,
    isBlink,
    resendMessage,
    blinkMessage,
    scrollTo
  };
};
__sfc_main.components = Object.assign({
  Icon,
  MessageQuote
}, __sfc_main.components);
export default __sfc_main;
