
import { ref, onMounted, onUnmounted } from "../../../../adapter-vue";
import { TUIStore, StoreName, TUITranslateService, TUIGroupService, TUIUserService } from "@tencentcloud/chat-uikit-engine";
import { isPC } from "../../../../utils/env";
import { IGroupApplicationListItem, IGroupApplicationType, IUserProfile } from "../../../../interface";
const __sfc_main = {};
__sfc_main.props = {
  groupID: {
    type: String,
    default: ""
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emits = __ctx.emit;
  const props = __props;
  const groupApplicationList = ref<Array<IGroupApplicationListItem>>([]);
  const groupApplicationCount = ref<number>(0);
  // - 申请类型：0 加群申请，2 邀请进群申请
  const getGroupApplicationList = () => {
    TUIGroupService.getGroupApplicationList().then((res: {
      data: {
        applicationList: Array<IGroupApplicationListItem>;
      };
    }) => {
      const applicationList = res.data.applicationList.filter((application: IGroupApplicationListItem) => application.groupID === props.groupID);
      let userIDList: Array<string> = [];
      applicationList.forEach((item: IGroupApplicationListItem) => {
        item.applicationType === 0 ? item.applicant && userIDList.push(item.applicant) : item.userID && userIDList.push(item.userID);
      });
      userIDList = Array.from(new Set(userIDList));
      TUIUserService.getUserProfile({
        userIDList: userIDList
      }).then((res: {
        data: Array<IUserProfile>;
      }) => {
        const profileList = res.data || [];
        profileList.forEach((profile: IUserProfile) => {
          applicationList.forEach((item: IGroupApplicationListItem) => {
            if (item.applicationType === 0 && profile.userID === item.applicant) {
              item.avatar = profile.avatar || "";
            }
            if (item.applicationType === 2 && profile.userID === item.userID) {
              item.nick = profile.nick || "";
              item.avatar = profile.avatar || "";
            }
            item.actionStatus = "";
          });
        });
        groupApplicationList.value = applicationList;
      });
    });
  };
  const onGroupApplicationCount = (count: number) => {
    groupApplicationCount.value = count;
  };
  TUIStore.watch(StoreName.CUSTOM, {
    groupApplicationCount: onGroupApplicationCount
  });
  onMounted(() => {
    getGroupApplicationList();
  });
  onUnmounted(() => {
    TUIStore.unwatch(StoreName.CUSTOM, {
      groupApplicationCount: onGroupApplicationCount
    });
  });
  const toggleApplicationList = () => {
    emits("toggleApplicationList");
  };
  const handleApplication = (item: IGroupApplicationListItem, type: string) => {
    TUIGroupService.handleGroupApplication({
      handleAction: type,
      application: {
        ...item
      }
    }).then(() => {
      item.actionStatus = type;
      // - 申请类型：0 加群申请，2 邀请进群申请
      const userID = item.applicationType === 0 ? item.applicant : item.userID;
      emits("handleGroupApplication", userID);
      groupApplicationCount.value -= 1;
      TUIStore.update(StoreName.CUSTOM, "groupApplicationCount", groupApplicationCount.value);
    });
  };
  return {
    TUITranslateService,
    isPC,
    groupApplicationList,
    toggleApplicationList,
    handleApplication
  };
};
export default __sfc_main;
