var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'tui-search-container',
    !_vm.isPC && 'tui-search-container-h5',
    _vm.isPC && `container-${_vm.props.popupPosition}`,
    `container-${_vm.props.searchType}`,
  ]},[_c('div',{class:[
      _vm.isPC && `tui-search-container-${_vm.props.popupPosition}`,
      !_vm.isPC && 'tui-search-container-h5-main',
    ]},[(_vm.props.searchType === 'conversation')?_c('div',{staticClass:"tui-search-header"},[_c('div',{staticClass:"tui-search-header-title"},[_vm._v(" "+_vm._s(_vm.TUITranslateService.t("TUISearch.搜索会话内容"))+" ")]),_c('div',{staticClass:"tui-search-header-close",on:{"click":_vm.closeSearchContainer}},[_c('Icon',{attrs:{"file":_vm.closeDarkIcon,"width":"14px","height":"14px"}})],1)]):_vm._e(),_c('div',{staticClass:"tui-search-tabs"},_vm._l((_vm.searchTypeList),function(tabItem,tabKey){return _c('div',{key:tabKey,class:[
          'tui-search-tabs-item',
          _vm.currentSearchMessageType.key === tabItem.key && 'tui-search-tabs-item-selected',
        ],on:{"click":function($event){return _vm.selectSearchType(tabItem)}}},[_vm._v(" "+_vm._s(_vm.TUITranslateService.t(`TUISearch.${tabItem.label}`))+" ")])}),0),_vm._t("input"),(_vm.isTimeTabsShow)?_c('div',{staticClass:"tui-search-time"},_vm._l((_vm.searchMessageTimeList),function(tabItem,tabKey){return _c('div',{key:tabKey,class:[
          'tui-search-time-item',
          _vm.currentSearchMessageTime.key === tabItem.key && 'tui-search-time-item-selected',
        ],on:{"click":function($event){return _vm.selectSearchTime(tabItem)}}},[(tabItem.key === 'all')?_c('div',{staticClass:"tui-search-time-item-picker"},[(!_vm.isDatePickerShow)?_c('div',{staticClass:"tui-search-time-item-all",on:{"click":function($event){$event.stopPropagation();return _vm.handleSelectAllTimeClicked.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.TUITranslateService.t(`TUISearch.选择时间`) + ": " + _vm.TUITranslateService.t(`TUISearch.全部`))+" "),_c('Icon',{attrs:{"file":_vm.downArrowIcon,"width":"14px","height":"14px"}})],1):_vm._e(),_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[(_vm.isDatePickerShow)?_c('DatePicker',{attrs:{"type":"range","rangeTableType":_vm.datePickerRangeDisplayType},on:{"pick":_vm.pickTimePeriod}}):_vm._e()],1),(_vm.isDatePickerShow)?_c('div',{staticClass:"tui-search-time-item-close",on:{"click":_vm.clearTimePicker}},[_c('Icon',{staticClass:"icon",attrs:{"file":_vm.closeIcon,"width":"14px","height":"14px"}})],1):_vm._e()]):_c('div',[_vm._v(" "+_vm._s(_vm.TUITranslateService.t(`TUISearch.${tabItem.label}`))+" ")])])}),0):_vm._e(),_vm._t("result")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }