import url from '../../api/base'
const fun = {
    //base64转换blob
    dataURItoBlob(dataURI){
        // base64 解码
        let byteString = window.atob(dataURI.split(',')[1]);
        let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i)
        }
        return new Blob([ab], {type: mimeString})
    },
    // base64 转 file
    base64ConvertFile(urlData, filename){
        if (typeof urlData != 'string') {
            return;
          }
          var arr = urlData.split(',')
          var type = arr[0].match(/:(.*?);/)[1]
          var fileExt = type.split('/')[1]
          var bstr = atob(arr[1])
          var n = bstr.length
          var u8arr = new Uint8Array(n)
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          return new File([u8arr], 'filename.' + fileExt, {
            type: type
          });
    },
    // file 转 base64
    jsFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64 = btoa(reader.result);
          resolve(base64);
        };
        reader.onerror = reject;
        reader.readAsBinaryString(file);
      });
    },
    // img name 转url链接
    urlImg(imgName,static_path){
      return `${url.baseUrl}img?file_name=${imgName}&static_path=${static_path}&token=${JSON.parse(localStorage.getItem("user")).token}`
    }
}

export default fun;