var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"toolbarItemRef",class:[
    'toolbar-item-container',
    !_vm.isPC && 'toolbar-item-container-h5',
    _vm.isUniFrameWork && 'toolbar-item-container-uni',
  ]},[_c('div',{class:[
      'toolbar-item-container-icon',
      !_vm.isPC && 'toolbar-item-container-h5-icon',
      _vm.isUniFrameWork && 'toolbar-item-container-uni-icon',
    ],on:{"click":_vm.toggleToolbarItem}},[_c('Icon',{staticClass:"icon",attrs:{"file":_vm.props.iconFile,"width":_vm.props.iconWidth,"height":_vm.props.iconHeight}})],1),(_vm.isUniFrameWork)?_c('div',{class:['toolbar-item-container-uni-title']},[_vm._v(" "+_vm._s(_vm.props.title)+" ")]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDialog),expression:"showDialog"}],ref:"dialogRef",class:[
      'toolbar-item-container-dialog',
      !_vm.isPC && 'toolbar-item-container-h5-dialog',
      _vm.isUniFrameWork && 'toolbar-item-container-uni-dialog',
    ]},[(_vm.props.needBottomPopup && !_vm.isPC)?_c('BottomPopup',{attrs:{"show":_vm.showDialog},on:{"onClose":_vm.onPopupClose}},[_vm._t("default")],2):_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }