import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
// import login from '../module/login.vue'
import Drugs from '../module/Drugs.vue'
import Pres from '../module/pres/pres.vue'
// import prescription from '../module/prescription.vue'
import overview from '../module/overview.vue'
import yaopinxuanzhe from '../module/yaopinxuanzhe.vue'
// import message from '../module/message.vue'
// import chat from '../module/chat.vue'
// import my from '../module/my.vue'
// import Password from '../components/Password.vue'
// import UserInfo from '../components/UserInfo.vue'
// import Patient from '../module/Patient.vue'
// import post_Patient from '../module/post_Patient.vue'
// import order from '../module/order.vue'
import drugs_details from '../module/drugs_details.vue'
// import disease from '../module/disease.vue'
// import tiaoxingma from '../module/tiaoxingma.vue'
// import info from '../module/info.vue'
// import Drugidentification from '../module/Drugidentification.vue'
import ceshichat from '../module/ceshichat.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
      path: "/",
      name: "index",
      component: resolve => require.ensure([], () => resolve(require('@/module/overview')), 'message'),
  
    },
    {
      path: "/index",
      name: "index",
      component: resolve => require.ensure([], () => resolve(require('@/module/overview')), 'message'),
    },
    {
      path: "/Drugs", // 商品 
      name: 'Drugs',
      component: Drugs,
      // component: resolve => require.ensure([], () => resolve(require('@/module/Drugs')), 'Drugs'),
      meta:{
        keepAlive:true
      }
    },
    {
      path: "/Pres", // 商品 
      name: 'Pres',
      component: resolve => require.ensure([], () => resolve(require('@/module/pres/pres')), 'prescription'),
      meta:{
        keepAlive:true
      }
    },
    {
      path: "/prescription",
      name: "prescription",
      component: resolve => require.ensure([], () => resolve(require('@/module/prescription')), 'prescription'),
    },
    {
      path: "/yaopinxuanzhe",
      name: "yaopinxuanzhe",
      component: resolve => require.ensure([], () => resolve(require('@/module/yaopinxuanzhe')), 'prescription'),
    },
    {
      path: "/message",
      name: "message",
      component: resolve => require.ensure([], () => resolve(require('@/module/message')), 'message'),
    },
    {
      path: "/chat",
      name: "chat",
      component: resolve => require.ensure([], () => resolve(require('@/module/chat')), 'chat'),
    },
    {
      path: "/my",
      name: "my",
      component: resolve => require.ensure([], () => resolve(require('@/module/my')), 'my'),
    },
    {
      path: "/login",
      name: "login",
      component: resolve => require.ensure([], () => resolve(require('@/module/login')), 'login'),
    },
    
    {
      path: "/drugs_details",
      name: "drugs_details",
      component: resolve => require.ensure([], () => resolve(require('@/module/drugs_details')), 'login'),
    },
    {
      path: "/Patient",
      name: "Patient",
      component: resolve => require.ensure([], () => resolve(require('@/module/Patient')), 'Patient'),
    },
    {
      path: "/post_Patient",
      name: "post_Patient",
      component: resolve => require.ensure([], () => resolve(require('@/module/post_Patient')), 'post_Patient'),
    },
    {
      path: "/order",
      name: "order",
      component: resolve => require.ensure([], () => resolve(require('@/module/order')), 'order'),
    },
    {
      path: "/disease",
      name: "disease",
      component: resolve => require.ensure([], () => resolve(require('@/module/disease')), 'disease'),
    },
    {
      path: "/info",
      name: "info",
      component: resolve => require.ensure([], () => resolve(require('@/module/info')), 'info'),
    },
    {
      path: "/Drugidentification",
      name: "Drugidentification",
      component: resolve => require.ensure([], () => resolve(require('@/module/Drugidentification')), 'Drugidentification'),
    },
    {
      path: "/Password",
      name: "Password",
      component: resolve => require.ensure([], () => resolve(require('@/components/Password')), 'Password'),
    },
    {
      path: "/UserInfo",
      name: "UserInfo",
      component: resolve => require.ensure([], () => resolve(require('@/components/UserInfo')), 'UserInfo'),
    },
    {
      path: "/wechat",
      name: "wechat",
      component: resolve => require.ensure([], () => resolve(require('@/module/wechat')), 'wechat'),
    },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
