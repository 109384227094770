
import Vue from "vue";
import footer1 from './components/footer.vue'
import domtoimage from 'dom-to-image';
import chat from './module/chat.vue'
import util from './assets/js/util.js'
import mixin from "@/utils/mixin";
import img from './assets/js/img.js'
import base from './module/api/base.js'
export default Vue.extend({
  mixins: [mixin],
  name: "App",
  components: {
    footer1,
    chat
  },
  data() {
    return {
      footer:true,
      chatShow:false,
      chatList: [
    
      ],
      //用户名
      userName: "尚云方",
      //输入内容
      inputValue: "",
      chatInfo:{},
      //滚动条距离顶部距离
      scrollTop:0,
      timer:null
    };
  },
  methods:{
    postChat(){
      if(this.$route.name == 'login'){
        return
      }
      this.$confirm('您确定联系运营人员吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            let app = document.getElementById('app')
            domtoimage.toPng(app,{height:document.getElementById('app').scrollHeight}).then(canvas=>{
                let blob = img.dataURItoBlob(canvas);
                let formData = new FormData();
                formData.append('file',blob,'123456.png');
                this.$api.article.upload('ComplainChat',formData).then(res=>{
                  console.log(res)
                  let img = res
                  this.apireturn('Complain','found_id',localStorage.getItem('userrole_id')).then(res=>{
                    if(res.data.result.id){
                        this.chatInfo = res.data.result
                        this.chatGetList(this.chatInfo.id)
                        this.chatShow =true
                        this.$refs.scrollBox.addEventListener('scroll',this.srTop)
                        this.setPageScrollTo()
                      }else{
                        let params = {
                          search_db:'Complain',
                        }
                        let data = {
                            created:{
                                pres_id:'None',
                                found_id:localStorage.getItem('userrole_id'),
                            }
                        }
                        this.$api.article.created(params,data).then(res=>{
                          this.chatInfo = res.data.result
                          let inputMsg = {
                              MSMediumText:img.data.result.file_name,
                              type:'img',
                              name:JSON.parse(localStorage.getItem('user')).user_account
                          }
                          this.postChatList(JSON.stringify(inputMsg))
                        })
                        this.chatShow =true
                        this.$refs.scrollBox.addEventListener('scroll',this.srTop)
                        this.setPageScrollTo()
                      }
                  })

                  
                })
              
            })
        }).catch(() => {
                   
        });
      // this.$alert('您确定联系管理员吗？', '提示', {
      //     confirmButtonText: '确定',
      //     callback: action => {
      //       let app = document.getElementById('app')
      //       domtoimage.toPng(app,{height:document.getElementById('app').scrollHeight}).then(canvas=>{
      //           let blob = img.dataURItoBlob(canvas);
      //           let formData = new FormData();
      //           formData.append('file',blob,'123456.png');
      //           this.$api.article.upload('ComplainChat',formData).then(res=>{
      //             console.log(res)
      //             let img = res
      //             this.apireturn('Complain','found_id',localStorage.getItem('userrole_id')).then(res=>{
      //               if(res.data.result.id){
      //                   this.chatInfo = res.data.result
      //                   this.chatGetList(this.chatInfo.id)
      //                   this.chatShow =true
      //                   this.$refs.scrollBox.addEventListener('scroll',this.srTop)
      //                   this.setPageScrollTo()
      //                 }else{
      //                   let params = {
      //                     search_db:'Complain',
      //                   }
      //                   let data = {
      //                       created:{
      //                           pres_id:'None',
      //                           found_id:localStorage.getItem('userrole_id'),
      //                       }
      //                   }
      //                   this.$api.article.created(params,data).then(res=>{
      //                     this.chatInfo = res.data.result
      //                     let inputMsg = {
      //                         MSMediumText:img.data.result.file_name,
      //                         type:'img',
      //                         name:JSON.parse(localStorage.getItem('user')).user_account
      //                     }
      //                     this.postChatList(JSON.stringify(inputMsg))
      //                   })
      //                   this.chatShow =true
      //                   this.$refs.scrollBox.addEventListener('scroll',this.srTop)
      //                   this.setPageScrollTo()
      //                 }
      //             })

                  
      //           })
              
      //       })
      //     }
      //   });
      
    },
    postChatList(contents){
      let params = {
          search_db:'ComplainChat',
      }
      let data = {
          created:{
              found_id:localStorage.getItem('userrole_id'),
              chat_id:this.chatInfo.id,
              contents:contents
          }
      }
      this.$api.article.created(params,data).then(res=>{
          console.log(res)
          this.chatGetList(this.chatInfo.id)
      })
    },
    chatGetList(id){
      if(!id){
                return
            }
            clearInterval(this.timer);
            console.log(id)
            let that = this
            let getApiChatList = function (){
                let params = {
                    search_db: 'ComplainChat',
                    query_type: 'equal',
                    order_by:'asc'
                };

                let data = {
                    query: [
                    
                    {
                        fild: { chat_id:id },
                        type: '=',
                        condition: '&&',
                    }
                    ],
                };
                that.$api.article.query2(params,data).then(res => {
                    let chatLIst = []
                    res.data.result.forEach(element => {
                        element.contents = JSON.parse(element.contents)
                        let  text = ''
                        if(element.contents.type == 'img'){
                            text = `${base.baseUrl}img?static_path=ComplainChat&userrole_id=${localStorage.getItem('userrole_id')}&token=${JSON.parse(localStorage.getItem('userinfo')).token}&file_name=${element.contents.MSMediumText}`

                        }else{
                            text = element.contents.MSMediumText
                        }
                        chatLIst.push(
                            {
                                date:element.created_at,
                                content:text,
                                type:element.contents.type,
                                username:element.contents.name,
                                position:element.found_id == localStorage.getItem('userrole_id') ? 'right' : 'left',
                                url:require("./assets/image/321.png")
                            }
                        )
                    });
                    that.chatList = chatLIst
                })
            }
            getApiChatList()
            this.timer = setInterval(function() {
              console.log(that.$route)
              if(that.$route.name !== 'login'){
                    getApiChatList()
                }
            }, 2000);

    },
    //返回
    onClickLeft() {
      console.log("返回");
      this.chatShow = false
    },
    //更多
    onClickRight() {
      console.log("按钮");
    },
    //滚动条默认滚动到最底部
    setPageScrollTo(s, c) {
      //获取中间内容盒子的可见区域高度
      this.scrollTop = document.querySelector("#box").offsetHeight;
      console.log(this.scrollTop)
      console.log('____________________________')
      setTimeout((res) => {
        //加个定时器，防止上面高度没获取到，再获取一遍。
        if (this.scrollTop != this.$refs.scrollBox.offsetHeight) {
          this.scrollTop = document.querySelector("#box").offsetHeight;
        }
      }, 100);
      //scrollTop：滚动条距离顶部的距离。
      //把上面获取到的高度座位距离，把滚动条顶到最底部
      this.$refs.scrollBox.scrollTop = this.scrollTop;
    },
    //滚动条到达顶部
    srTop(){
      //判断：当滚动条距离顶部为0时代表滚动到顶部了
      if(this.$refs.scrollBox.scrollTop==0){
        //逻辑简介：
        //到顶部后请求后端的方法，获取第二页的聊天记录，然后插入到现在的聊天数据前面。
        //如何插入前面：可以先把获取的数据保存在 A 变量内，然后 this.chatList=A.concat(this.chatList)把数组合并进来就可以了

        //拿聊天记录逻辑:
        //第一次调用一个请求拉历史聊天记录，发请求时参数带上页数 1 传过去，拿到的就是第一页的聊天记录，比如一次拿20条。你显示出来
        //然后向上滚动到顶部时，触发新的请求，在请求中把分页数先 +1 然后再请求，这就拿到了第二页数据，然后通过concat合并数组插入进前面，依次类推，功能完成！
        console.log('到顶了，滚动条位置 :',this.$refs.scrollBox.scrollTop);
      }
    },
    sendOut(){
      console.log('发送成功');
      if(this.inputValue == ''){

        return
      }
      let inputMsg = {
          MSMediumText:this.inputValue,
          type:'text',
          name:JSON.parse(localStorage.getItem('user')).user_account
      }
      this.postChatList(JSON.stringify(inputMsg))
      this.inputValue = ''
      
    }

  },
  mounted(){
    // this.setPageScrollTo()
    //创建监听内容部分滚动条滚动
   
  },
  created(){
    if(window.location.hash == '#/login'){
      this.footer = false
    }
    // this.IMSIG()

    
  }
});
