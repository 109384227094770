
import { TUITranslateService } from "@tencentcloud/chat-uikit-engine";
import { ref, watchEffect } from "../../../../adapter-vue";
import documentLink from "../../../../utils/documentLink";
import Icon from "../../../common/Icon.vue";
import selectedIcon from "../../../../assets/icon/selected.svg";
import { groupIntroConfig } from "./config";
const __sfc_main = {};
__sfc_main.props = {
  groupType: {
    type: String,
    default: ''
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const type = groupIntroConfig;
  const selectType = ref();
  const emit = __ctx.emit;
  watchEffect(() => {
    selectType.value = props.groupType;
  });
  const selected = (item: any) => {
    selectType.value = item.type;
    emit('selectType', item.type);
  };
  return {
    TUITranslateService,
    documentLink,
    selectedIcon,
    type,
    selectType,
    selected
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
