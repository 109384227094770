
import { withDefaults } from "../../../../adapter-vue";
import { TUITranslateService, IMessageModel } from "@tencentcloud/chat-uikit-engine";
import Icon from "../../../common/Icon.vue";
import files from "../../../../assets/icon/files.png";
import type { IFileMessageContent } from "../../../../interface";
import { isPC } from "../../../../utils/env";
const __sfc_main = {};
__sfc_main.props = {
  content: {
    key: "content",
    required: false,
    type: null,
    default: () => ({}) as IFileMessageContent
  },
  messageItem: {
    key: "messageItem",
    required: false,
    type: null,
    default: () => ({}) as IMessageModel
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;

  // todo: 区分 web 和 uniapp
  const download = () => {
    if (props.messageItem.hasRiskContent) {
      return;
    }
    const option = {
      mode: "cors",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded"
      })
    } as RequestInit;
    // 浏览器支持fetch则用blob下载，避免浏览器点击a标签，跳转到新页面预览的行为
    // If the browser supports fetch, use blob to download, so as to avoid the browser clicking the a tag and jumping to the preview of the new page
    if ((window as any)?.fetch) {
      fetch(props.content.url, option).then(res => res.blob()).then(blob => {
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = props.content.name;
        a.click();
      });
    } else {
      const a = document.createElement("a");
      a.href = props.content.url;
      a.target = "_blank";
      a.download = props.content.name;
      a.click();
    }
  };
  return {
    TUITranslateService,
    files,
    props,
    download
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
