
export interface IOverlayProps {
  zIndex?: number;
  bgColor?: string;
}
import { withDefaults } from '../../../adapter-vue';
const __sfc_main = {};
__sfc_main.props = {
  zIndex: {
    key: "zIndex",
    required: false,
    type: Number,
    default: 9999
  },
  bgColor: {
    key: "bgColor",
    required: false,
    type: String,
    default: 'rgba(0, 0, 0, 0.6)'
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emits = __ctx.emit;
  const props = __props;
  function click() {
    emits('clickHandler');
  }
  function tap() {
    emits('touchHandler');
  }
  return {
    props,
    click,
    tap
  };
};
export default __sfc_main;
