const TUIChat = {
  "撤回": "撤回",
  "删除": "删除",
  "复制": "复制",
  "已过撤回时限": "已过撤回时限",
  "重新发送": "重新发送",
  "转发": "转发",
  "引用": "引用",
  "回复": "回复",
  "回复详情": "回复详情",
  "条回复": "条回复",
  "打开": "打开",
  "已读": "已读",
  "未读": "未读",
  "人已读": "人已读",
  "人未读": "人未读",
  "人关闭阅读状态": "人关闭阅读状态",
  "全部已读": "全部已读",
  "消息详情": "消息详情",
  "关闭阅读状态": "关闭",
  "您": "您",
  "撤回了一条消息": "撤回了一条消息",
  "重新编辑": "重新编辑",
  "选择提醒的人": "选择提醒的人",
  "我": "我",
  "自定义消息": "自定义消息",
  "查看更多": "查看更多",
  "确认重发该消息？": "确认重发该消息？",
  "取消": "取消",
  "确定": "确定",
  "转发给": "转发给",
  "请输入消息": "请输入消息",
  "描述": "描述",
  "经度": "经度",
  "纬度": "纬度",
  "消息": "message",
  "图片": "[图片]",
  "语音": "[语音]",
  "视频": "[视频]",
  "表情": "[表情]",
  "文件": "[文件]",
  "自定义": "[自定义消息]",
  "管理员开启全员禁言": "管理员开启全员禁言",
  "您已被管理员禁言": "您已被管理员禁言",
  "按Enter发送，Ctrl+Enter换行": "按Enter发送，Ctrl+Enter换行",
  "单击下载": "单击下载",
  "该消息不存在": "该消息不存在",
  "无法定位到原消息": "无法定位到原消息",
  "对方正在输入": "对方正在输入...",
  "回到最新位置": "回到最新位置",
  "条新消息": "条新消息",
  "【安全提示】本 APP 仅用于体验腾讯云即时通信 IM 产品功能，不可用于业务洽谈与拓展。请勿轻信汇款、中奖等涉及钱款的信息，勿轻易拨打陌生电话，谨防上当受骗。": "【安全提示】本 APP 仅用于体验腾讯云即时通信 IM 产品功能，不可用于业务洽谈与拓展。请勿轻信汇款、中奖等涉及钱款的信息，勿轻易拨打陌生电话，谨防上当受骗。",
  "点此投诉": "点此投诉",
  "语音通话": "语音通话",
  "视频通话": "视频通话",
  "发起群语音": "发起群语音",
  "发起群视频": "发起群视频",
  "已接听": "已接听",
  "拒绝通话": "拒绝通话",
  "无应答": "无应答",
  "取消通话": "取消通话",
  "发起通话": "发起通话",
  "结束群聊": "结束群聊",
  "通话时长": "通话时长",
  "欢迎使用TUICallKit": "欢迎使用腾讯云音视频通话组件TUICallKit ",
  "检测到您暂未集成TUICallKit，无法体验音视频通话功能": "检测到您暂未集成TUICallKit，无法体验音视频通话功能。",
  "请点击": "请点击",
  "集成TUICallKit": "集成 TUICallKit ",
  "开通腾讯实时音视频服务": "开通腾讯实时音视频服务",
  "进行体验": "进行体验。",
  "您当前购买使用的套餐包暂未开通群消息已读回执功能": "您当前购买使用的套餐包暂未开通【群消息已读回执】功能，请升级至旗舰版进行体验",
  "您当前购买使用的套餐包暂未开通在线用户列表功能": "您当前购买使用的套餐包暂未开通【在线用户列表】功能，请升级至旗舰版进行体验",
  "您当前购买使用的套餐包暂未开通此功能": "您当前购买使用的套餐包暂未开通此功能。",
  "条入群申请": "条入群申请",
  "点击处理": "点击处理",
  "申请加入": "申请加入",
  "同意": "同意",
  "拒绝": "拒绝",
  "已同意": "已同意",
  "已拒绝": "已拒绝",
  "内容包含敏感词汇": "内容包含敏感词汇",
  "图片消息失败,无效的图片格式": "图片消息失败,无效的图片格式",
  "文件消息失败,禁止发送违规封禁的文件": "文件消息失败,禁止发送违规封禁的文件",
  "文件不存在,请检查文件路径是否正确": "文件不存在,请检查文件路径是否正确",
  "文件大小超出了限制,如果上传文件,最大限制是100MB": "文件大小超出了限制,如果上传文件,最大限制是100MB",
  "消息长度超出限制,消息长度不要超过12K": "消息长度超出限制,消息长度不要超过12K",
  "消息或者资料中文本存在敏感内容,发送失败": "消息或者资料中文本存在敏感内容,发送失败",
  "消息中图片存在敏感内容,发送失败": "消息中图片存在敏感内容,发送失败",
  "涉及敏感内容": "涉及敏感内容",
  "发送失败": "发送失败",
  "引用失败": "引用失败",
  "无法查看": "无法查看",
  "无法收听": "无法收听",
};

export default TUIChat;
