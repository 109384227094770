/**
 * 接口域名的管理
 */

const base = {
    auth_url:'https://auth.net-sun.com/',
    baseUrl: "https://api.net-sun.com/",
    weburl: "https://cloud.net-sun.com/",
    // auth_url:'https://auth.net-sun.com/',
    // baseUrl: "http://47.94.43.29:1780/",
    // weburl: "https://cloud.net-sun.com/",
}
export default base;