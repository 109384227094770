
import Icon from "../../../../common/Icon.vue";
import playIcon from "../../../../../assets/icon/video-play.png";
const __sfc_main = {};
__sfc_main.props = {
  messageContent: {
    type: Object,
    default: () => ({})
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  return {
    playIcon
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
