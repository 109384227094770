<template>
    <div id="footer_">
      <!-- <div  class="FilingCode">药品医疗器械网络信息服务备案:（京）网药械信息备字（2023）第00362号 <br> 
        京ICP备2023022735号-1
      </div> -->
      <van-tabbar v-model="active" active-color="#20d8de" :route="true" @change="router_change">
        <van-tabbar-item icon="wap-home-o" name="/index">首页</van-tabbar-item>
        <van-tabbar-item icon="waiting-call" v-if="number !==0" :info="number" name="/message">问诊</van-tabbar-item>
        <van-tabbar-item icon="waiting-call" v-if="number ==0"  name="/message">问诊</van-tabbar-item>
        <van-tabbar-item icon="user-o" name="/my">我的</van-tabbar-item>
      </van-tabbar>
    </div>
</template>
<script>
import method from '../assets/js/util.js'
import mixin from "@/utils/mixin";
export default {
  mixins: [mixin],
    data(){
        return{
          active:'/',
          number:0
        }
    },
    methods:{
      router_change(){
        sessionStorage.removeItem('Drugs');
        sessionStorage.removeItem('Current');
        sessionStorage.removeItem('disease');
        if(this.$route.path !== this.active){
          this.$router.push(this.active)
        }
      },
      news(){
        if(!sessionStorage.getItem('pharmacy_id')){
          return
        }
        var d = new Date().getTime()
        this.date = [method.formatDate(d).slice(0,10)+ ' 00:00:01',method.formatDate(d).slice(0,10)+' 23:59:59']
        this.$api.article.query({search_db:'Pres',query_type:'equal',start_time:this.date[0],end_time:this.date[1]},{query:{found_id:localStorage.getItem('userrole_id'),pharmacy_id:sessionStorage.getItem('pharmacy_id')}}).then(res => {
            let number = 0
            res.data.result.forEach(element => {
              if(element.doctorCheckStatus == 0 || element.doctorCheckStatus == '1'){
                number +=1
              }
              this.number = number
            });
        })
      }
    },
    created(){
      if(localStorage.getItem('userinfo')){
      this.news()

      }
    }
 
}
</script>
<style scopde>
.mint-tab-item-icon img{
  width: 100px;
  height: 100px;
}
/* .FilingCode{
  position: fixed;
  bottom:55px;
  width: 100%;
  font-size: 10px;
  font-family: Adobe Heiti Std;
  font-weight: 400;font-weight: 400;
  color: #666;
  text-align: center;
} */
</style>