const components = {
  "取消": "取消",
  "完成": "完成",
  "已选中": "已选中",
  "人": "人",
  "保存": "保存",
  "请输入userID": "请输入userID",
  "请输入groupID": "请输入groupID",
  "确定": "确定",
  "全选": "全选",
  "已在群聊中": "已在群聊中",
  "查看更多": "查看更多"
}

export default components;
