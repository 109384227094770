
import en from './en';
import zh from './zh_cn';

const messages = {
  ...en,
  ...zh,
};

export default messages;
