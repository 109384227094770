import base from './base';
// 导入接口域名列表
import axios from '../../common/http'; // 导入http中创建的axios实例
let headers = {
        headers: {
            'X-Auth-Token': localStorage.getItem('tokenlo')
        }
}
const article = {
        //多字段查询
        query(params,data={query:{}}){
            let url = `${base.baseUrl}query?`
            Object.keys(params).forEach(
                (item) => (url += `${item}=${params[item]}&`)
            );
            return axios.post(url.substring(0, url.length - 1),data,headers);
        },
        //多字段查询
        query2(params,data={query:{}}){
            let url = `${base.baseUrl}query2?`
            Object.keys(params).forEach(
                (item) => (url += `${item}=${params[item]}&`)
            );
            return axios.post(url.substring(0, url.length - 1),data,headers);
        },
        //获取 用户角色关联 
        getrole(userrole){
            let url = `${base.baseUrl}getrole?userrole=${userrole}`
            return axios.get(url,headers);
        },
        //查看权限
        userpower(role_id){
            return axios.get(`${base.baseUrl}userpower?role_id=${role_id}`);
        },
        postuserrole(data){
            let url = `${base.baseUrl}getrole?userrole`
            return axios.post(url,data,headers);
        },
        //创建
        created(params,data){
            let url = `${base.baseUrl}created?`
            Object.keys(params).forEach(
                (item) => (url += `${item}=${params[item]}&`)
            );
            return axios.post(url.substring(0, url.length - 1),data);
        },
        //创建用户信息
        usercreated(data){
            let url = `${base.baseUrl}usercreated`
            return axios.post(url,data,headers);
        },
        //添加用户角色
        userrole(data){
            let url = `${base.baseUrl}userrole`
            return axios.post(url,data,headers);
        },
        //删除
        Delete(params,data) {
            let url = `${base.baseUrl}delete?`
            Object.keys(params).forEach(
                (item) => (url += `${item}=${params[item]}&`)
            )
            return axios.put(url.substring(0, url.length - 1),data);
        },
        //修改
        update(params,data) {
            let url = `${base.baseUrl}update?`
            Object.keys(params).forEach(
                (item) => (url += `${item}=${params[item]}&`)
            )
            return axios.put(url.substring(0, url.length - 1),data);
        },


        //获取 im 启用
        InternetHospital(params,data={query:{}}){
            let url = `${base.baseUrl}InternetHospital?`
            Object.keys(params).forEach(
                (item) => (url += `${item}=${params[item]}&`)
            );
            return axios.post(url.substring(0, url.length - 1),data,headers);
        },
        //查询用户最近使用药物
        GetPresMedicine(id){
            return axios.get(`${base.baseUrl}GetPresMedicine?patientId=${id}`);
        },
        //扫码获取药品信息
        getdrug(drug_no,pharmacy_id){
            return axios.get(`${base.baseUrl}getdrug?drug_no=${drug_no}&pharmacy_id=${pharmacy_id}`);
        },
        //上传
        upload(static_path,img) {
            return axios.post(`${base.baseUrl}img?static_path=${static_path}`,img);
        },
        //获取阿里云ocr识别的信息
        ocrTxt(file_name,static_path,ocr_type) {
            return axios.get(`${base.baseUrl}ocrTxt?file_name=${file_name}&static_path=${static_path}&ocr_type=${ocr_type}`);
        },
        CheckInquiry(data){
            return axios.post(`${base.baseUrl}CheckInquiry`,data);
        },
        //获取用户医保状态
        UserMedicalInsurance(cardNo) {
            return axios.get(`${base.baseUrl}UserMedicalInsurance?cardNo=${cardNo}`);
        },
        //获取医生排班
        get_doctor(id){
            return axios.get(`${base.baseUrl}get_doctor?id=${id}`);
        },
        //获取医保支付
        YBPayImg(id,pay_type = 1,ih_type = localStorage.getItem('YBPayImg')){
            return axios.get(`${base.baseUrl}YBPayImg?orderNo=${id}&pay_type=${pay_type}&ih_type=${ih_type}`);
        },
        wechat_bind(wechat_code){
            return axios.get(`${base.baseUrl}wechat_bind?wechat_code=${wechat_code}`);
        },
        // 发起互联网医院问诊
        CreatedInternetPres(data){
            return axios.post(`${base.baseUrl}CreatedInternetPres`,data);
        },
        AccelerationPres(type,data){
            let url = `${base.baseUrl}AccelerationPres?acceleration_type=${type}`
            return axios.post(url,data,headers);
        },
        //查询并同步互联网问诊信息
        syncPres(pres_id){
            return axios.get(`${base.baseUrl}querypres?pres_id=${pres_id}`);
        },
        //查询并同步互联网问诊信息
        HelpDrugs(pharmacy_id,data){
            return axios.post(`${base.baseUrl}HelpDrugs?pharmacy_id=${pharmacy_id}`,data);
        },
        //延时福利单
        welfare_dan(id){
            return axios.get(`${base.baseUrl}welfare_dan?id=${id}`);
        },
        //获取互联网医院名字
        getihname(){
            return axios.get(`${base.baseUrl}getihname?pharmacy_id=${sessionStorage.getItem('pharmacy_id')}`);
        },
        //更新互联网医院用户信息
        postihuserinfo(ih_type){
            return axios.post(`${base.baseUrl}postihuserinfo?ih_type=${ih_type}&pharmacy_id=${sessionStorage.getItem('pharmacy_id')}`,{});
        },
        //新老圆心api
        InternetHospitalGet(params,data){
            let url = `${base.baseUrl}InternetHospitalGet?`
            Object.keys(params).forEach(
                (item) => (url += `${item}=${params[item]}&`)
            );
            data['pharmacy_id'] = sessionStorage.getItem('pharmacy_id')
            return axios.post(url.substring(0, url.length - 1),data,headers);
        },

        //检查是否重复用药
        RecentDrugs(data){
            return axios.post(`${base.baseUrl}RecentDrugs`,data);
        },
        
        img_info(file_name,check){
            return axios.get(`${base.baseUrl}img_info?file_name=${file_name}&check=${check}`);
        },

        //修改复诊凭证
        OrderImage(data){
            return axios.post(`${base.baseUrl}OrderImage`,data);
        },
        getAccessToken(){
            return axios.get(`${base.baseUrl}wechat_api/?access_token=true`);
        },
        getticket(){
            return axios.get(`${base.baseUrl}wechat_ticket`);
        }
}

export default article;