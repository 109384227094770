
import { TUITranslateService } from "@tencentcloud/chat-uikit-engine";
import { ref } from "../../../adapter-vue";
import api from "@/module/api/index";
import router from "@/router/index";
import { Message } from 'element-ui';
import { isPC } from "../../../utils/env";
const __sfc_main = {};
__sfc_main.props = {
  enableSend: {
    type: Boolean,
    default: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emits = __ctx.emit;
  const sendMessage = () => {
    console.log(router);
    emits("sendMessage");
    api.article.query({
      search_db: 'Pres',
      query_type: 'first'
    }, {
      query: {
        pres_id: router.history.current.query.pres_id
      }
    }).then(res => {
      if (res.data.result.doctorCheckStatus == '3') {
        // this.$message('该问诊已被医生拒绝，请返回退款，重新申请新问诊');
        Message({
          message: '该问诊已被医生拒绝，请返回退款，重新申请新问诊',
          type: 'warning',
          duration: 5000
        });
      }
    });
  };
  return {
    TUITranslateService,
    isPC,
    props,
    sendMessage
  };
};
export default __sfc_main;
