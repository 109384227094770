
import { ref, watch, onMounted } from "../../../adapter-vue";
import { TUIStore, StoreName } from "@tencentcloud/chat-uikit-engine";
import TUICore, { ExtensionInfo, TUIConstants } from "@tencentcloud/tui-core";
import Icon from "../../common/Icon.vue";
import searchMoreSVG from "../../../assets/icon/search-more.svg";
import { isUniFrameWork } from "../../../utils/env";
const __sfc_main = {};
__sfc_main.props = {
  searchType: {
    type: String,
    default: "global",
    // "global":全局搜索, "conversation":会话内搜索
    validator(value: string) {
      return ["global", "conversation"].includes(value);
    }
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const searchMoreRef = ref<HTMLElement | null>();
  const isListShow = ref<boolean>(false);
  const toggleMore = () => {
    isListShow.value = !isListShow.value;
  };
  const extensionList = ref<Array<ExtensionInfo>>([]);
  const handleMenu = (item: any) => {
    const {
      listener = {
        onClicked: () => {}
      }
    } = item;
    listener.onClicked(item);
    toggleMore();
  };
  onMounted(() => {
    // extensions
    extensionList.value = [...TUICore.getExtensionList(TUIConstants.TUISearch.EXTENSION.SEARCH_MORE.EXT_ID)];
    // hide conversation header
    TUICore.callService({
      serviceName: TUIConstants.TUIConversation.SERVICE.NAME,
      method: TUIConstants.TUIConversation.SERVICE.METHOD.HIDE_CONVERSATION_HEADER,
      params: {}
    });
  });
  watch(() => isListShow.value, () => {
    if (isListShow.value) {
      onClickOutside(searchMoreRef.value);
      TUIStore.update(StoreName.CUSTOM, "currentSearchingStatus", {
        isSearching: false,
        searchType: props.searchType
      });
    }
  });

  // 全局搜索dialog-点击外侧关闭
  // click outside
  let clickOutside = false;
  let clickInner = false;
  const onClickOutside = (component: any) => {
    if (isUniFrameWork) {
      return;
    }
    document.addEventListener("click", onClickDocument);
    component?.addEventListener && component?.addEventListener("click", onClickTarget);
  };
  const removeClickListener = (component: any) => {
    if (isUniFrameWork) {
      return;
    }
    document.removeEventListener("click", onClickDocument);
    component?.removeEventListener && component?.removeEventListener("click", onClickTarget);
  };
  const onClickDocument = () => {
    clickOutside = true;
    if (!clickInner && clickOutside) {
      isListShow.value = false;
    }
    clickOutside = false;
    clickInner = false;
  };
  const onClickTarget = () => {
    clickInner = true;
  };
  return {
    searchMoreSVG,
    searchMoreRef,
    isListShow,
    toggleMore,
    extensionList,
    handleMenu
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
