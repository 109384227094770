const component = {
  "取消": "Cancel",
  "完成": "Done",
  "已选中": "Selected",
  "人": "",
  "保存": "Save",
  "请输入userID": "Enter a userID",
  "请输入groupID": "Enter a groupID",
  "确定": "submit",
  "全选": "All",
  "已在群聊中": "exists",
  "查看更多": "More"
}

export default component;
