const TUIContact = {
  "群聊通知": "Group Chat Notifications",
  "系统通知": "System Notifications",
  "我的群聊": "My Group Chats",
  "输入ID": "Enter a groupID/userID",
  "群名称": "Group name",
  "群ID": "Group ID",
  "加入群聊": "Join group chat",
  "解散群聊": "Disband group chat",
  "退出群聊": "Quit group chat",
  "进入群聊": "Enter group chat",
  "群类型": "Group type",
  "请填写验证信息": "Enter the verification info",
  "申请加入": "Request to join",
  "已申请":"Requested",
  "接受": "Accept",
  "拒绝": "Refuse",
  "已处理": "Handled",
  "添加群聊": "add group chat",
  "已加入": "joined",
  "该群组不存在": "The group does not exist",
  "加群": "join",
  "我的好友": "Friend",
  "发送消息": "Send Message",
  "个性签名": "Signature",
  "等待管理员同意": "Successful application, waiting for the administrator to agree to join the group application",
  "加群成功": "Join the group successfully",
  "您已是群成员": "You are already a group member",
  "新的联系人": "New Contact",
  "黑名单": "Black List",
  "无搜索结果": "No Result"
}

export default TUIContact;
