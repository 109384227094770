
import TUIChatEngine, { TUIStore, StoreName, TUITranslateService, IMessageModel } from "@tencentcloud/chat-uikit-engine";
import { ref, watchEffect, computed } from "../../../../adapter-vue";
import { isPC, isUniFrameWork } from "../../../../utils/env";
import { TUIGlobal } from "../../../../utils/universal-api/index";
import Icon from "../../../common/Icon.vue";
import { Toast, TOAST_TYPE } from "../../../common/Toast/index";
import delIcon from "../../../../assets/icon/msg-del.svg";
import copyIcon from "../../../../assets/icon/msg-copy.svg";
import quoteIcon from "../../../../assets/icon/msg-quote.svg";
import revokeIcon from "../../../../assets/icon/msg-revoke.svg";
import forwardIcon from "../../../../assets/icon/msg-forward.svg";
const __sfc_main = {};
__sfc_main.props = {
  messageItem: {
    type: Object,
    default: () => ({})
  },
  isShowTool: {
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const TYPES = TUIChatEngine.TYPES;
  const actionItems = [{
    text: TUITranslateService.t("TUIChat.打开"),
    iconUrl: copyIcon,
    renderCondition() {
      if (!message.value) return false;
      return isPC && (message.value?.type === TYPES.MSG_FILE || message.value?.type === TYPES.MSG_VIDEO || message.value?.type === TYPES.MSG_IMAGE);
    },
    clickEvent: openMessage
  }, {
    text: TUITranslateService.t("TUIChat.复制"),
    iconUrl: copyIcon,
    renderCondition() {
      if (!message.value) return false;
      return isUniFrameWork && message.value?.type === TYPES.MSG_TEXT;
    },
    clickEvent: copyMessage
  }, {
    text: TUITranslateService.t("TUIChat.撤回"),
    iconUrl: revokeIcon,
    renderCondition() {
      if (!message.value) return false;
      return message.value?.flow === "out" && message.value?.status === "success";
    },
    clickEvent: revokeMessage
  }, {
    text: TUITranslateService.t("TUIChat.删除"),
    iconUrl: delIcon,
    renderCondition() {
      if (!message.value) return false;
      return message.value?.status === "success";
    },
    clickEvent: deleteMessage
  }, {
    text: TUITranslateService.t("TUIChat.转发"),
    iconUrl: forwardIcon,
    renderCondition() {
      if (!message.value) return false;
      return message.value?.status === "success";
    },
    clickEvent: forwardSingleMessage
  }, {
    text: TUITranslateService.t("TUIChat.引用"),
    iconUrl: quoteIcon,
    renderCondition() {
      if (!message.value) return false;
      const _message = TUIStore.getMessageModel(message.value.ID);
      return message.value?.status === "success" && !_message.getSignalingInfo();
    },
    clickEvent: quoteMessage
  }];
  const message = ref<IMessageModel>();
  watchEffect(() => {
    message.value = TUIStore.getMessageModel(props.messageItem.ID);
  });
  const isAllActionItemInvalid = computed(() => {
    for (let i = 0; i < actionItems.length; ++i) {
      if (actionItems[i].renderCondition()) {
        return false;
      }
    }
    return true;
  });
  function getFunction(index: number) {
    // 兼容 vue2 小程序的写法 不允许动态绑定
    actionItems[index].clickEvent();
  }
  function openMessage() {
    let url = "";
    switch (message.value?.type) {
      case TUIChatEngine.TYPES.MSG_FILE:
        url = message.value.payload.fileUrl;
        break;
      case TUIChatEngine.TYPES.MSG_VIDEO:
        url = message.value.payload.remoteVideoUrl;
        break;
      case TUIChatEngine.TYPES.MSG_IMAGE:
        url = message.value.payload.imageInfoArray[0].url;
        break;
    }
    window?.open(url, "_blank");
  }
  function revokeMessage() {
    if (!message.value) return;
    // 获取 messageModel
    const messageModel = TUIStore.getMessageModel(message.value.ID);
    messageModel.revokeMessage().catch((error: any) => {
      // 调用异常时业务侧可以通过 promise.catch 捕获异常进行错误处理
      if (error.code = 20016) {
        const message = TUITranslateService.t("TUIChat.已过撤回时限");
        Toast({
          message,
          type: TOAST_TYPE.ERROR
        });
      }
    });
  }
  function deleteMessage() {
    if (!message.value) return;
    // 获取 messageModel
    const messageModel = TUIStore.getMessageModel(message.value.ID);
    messageModel.deleteMessage();
  }
  function copyMessage() {
    if (isUniFrameWork) {
      TUIGlobal?.setClipboardData({
        data: message.value?.payload?.text
      });
    }
  }
  function forwardSingleMessage() {
    if (!message.value) return;
    TUIStore.update(StoreName.CUSTOM, "singleForwardMessageID", message.value.ID);
  }
  function quoteMessage() {
    if (!message.value) return;
    message.value.quoteMessage();
  }
  return {
    isPC,
    actionItems,
    isAllActionItemInvalid,
    getFunction
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
