
import TUIChatEngine, { TUIStore, StoreName, TUITranslateService, IConversationModel } from "@tencentcloud/chat-uikit-engine";
import TUICore, { TUIConstants, ExtensionInfo } from "@tencentcloud/tui-core";
import { ref } from "../../../adapter-vue";
import Icon from "../../common/Icon.vue";
import backSVG from "../../../assets/icon/back.svg";
import { isPC } from "../../../utils/env";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emits = __ctx.emit;
  const currentConversation = ref<IConversationModel>();
  const currentConversationName = ref("");
  const typingStatus = ref(false);
  const groupID = ref("");
  const extensions = ref<ExtensionInfo>([]);
  console.log(emits);
  console.log(currentConversation);
  console.log(currentConversationName);
  console.log(typingStatus);
  console.log(groupID);
  console.log(extensions);
  TUIStore.watch(StoreName.CONV, {
    currentConversation: (conversation: IConversationModel) => {
      const isGroup = conversation?.type === TUIChatEngine.TYPES.CONV_GROUP;
      if (isGroup && currentConversation.value?.conversationID !== conversation?.conversationID) {
        extensions.value = TUICore.getExtensionList(TUIConstants.TUIChat.EXTENSION.CHAT_HEADER.EXT_ID, {
          filterManageGroup: isGroup
        });
      }
      if (!isGroup) {
        extensions.value = [];
      }
      currentConversation.value = conversation;
      groupID.value = currentConversation.value?.groupProfile?.groupID;
      currentConversationName.value = currentConversation?.value?.getShowName();
    }
  });
  TUIStore.watch(StoreName.CHAT, {
    typingStatus: (status: boolean) => {
      typingStatus.value = status;
      switch (typingStatus.value) {
        case true:
          currentConversationName.value = TUITranslateService.t("TUIChat.对方正在输入");
          break;
        case false:
          currentConversationName.value = currentConversation?.value?.getShowName();
          break;
      }
    }
  });
  const closeChat = (conversationID: string) => {
    // emits("closeChat", conversationID);
    window.location.replace(`${window.location.origin}/#/message`);
  };
  const handleExtensions = (item: ExtensionInfo) => {
    item.listener.onClicked({
      groupID: groupID.value
    });
  };
  return {
    backSVG,
    isPC,
    currentConversation,
    extensions,
    closeChat,
    handleExtensions
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
