
import { ref } from "../../../../../adapter-vue";
const __sfc_main = {};
__sfc_main.props = {
  content: {
    type: Array,
    default: []
  },
  highlightType: {
    type: String,
    default: "font" // "font":字体高亮 "background":背景高亮
  },
  displayType: {
    type: String,
    default: "info" // "info":信息展示形式 "bubble": 气泡展示形式
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const contentText = ref<Array<{
    text: string;
    isHighlight: boolean;
  }>>(props.content);
  return {
    contentText
  };
};
export default __sfc_main;
