
import { TUIChatService, TUIStore, StoreName, IConversationModel, SendMessageParams } from "@tencentcloud/chat-uikit-engine";
import { ref } from "../../../../adapter-vue";
import { isPC, isUniFrameWork } from "../../../../utils/env";
import ToolbarItemContainer from "../toolbar-item-container/index.vue";
import fileIcon from "../../../../assets/icon/files.png";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emits = __ctx.emit;
  const inputRef = ref();
  const currentConversation = ref<IConversationModel>();
  TUIStore.watch(StoreName.CONV, {
    currentConversation: (conversation: IConversationModel) => {
      currentConversation.value = conversation;
    }
  });
  const onIconClick = () => {
    if (isUniFrameWork) {
      // uniapp app 不支持选择文件发送
      return;
    } else {
      inputRef?.value?.click && inputRef?.value?.click();
    }
  };
  const sendFileMessage = (e: any) => {
    if (e?.target?.files?.length <= 0) {
      return;
    }
    const options = {
      to: currentConversation?.value?.groupProfile?.groupID || currentConversation?.value?.userProfile?.userID,
      conversationType: currentConversation?.value?.type,
      payload: {
        file: e?.target
      }
    } as SendMessageParams;
    TUIChatService.sendFileMessage(options);
    e.target.value = "";
  };
  return {
    isPC,
    isUniFrameWork,
    fileIcon,
    inputRef,
    onIconClick,
    sendFileMessage
  };
};
__sfc_main.components = Object.assign({
  ToolbarItemContainer
}, __sfc_main.components);
export default __sfc_main;
