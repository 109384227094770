
import { watchEffect, ref } from "../../../../adapter-vue";
const __sfc_main = {};
__sfc_main.props = {
  content: {
    type: Object,
    default: () => ({})
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const data = ref();
  watchEffect(() => {
    data.value = props.content;
  });
  return {
    data
  };
};
export default __sfc_main;
