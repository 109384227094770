
import { TUIStore, StoreName, IConversationModel } from "@tencentcloud/chat-uikit-engine";
import { ref } from "../../../adapter-vue";
import MessageInputEditor from "./message-input-editor.vue";
import MessageInputAt from "./message-input-at/index.vue";
import MessageInputButton from "./message-input-button.vue";
import MessageInputQuote from "./message-input-quote/index.vue";
import { sendMessages, sendTyping } from "../utils/sendMessage";
import { isPC, isH5 } from "../../../utils/env";
const __sfc_main = {};
__sfc_main.props = {
  placeholder: {
    type: String,
    default: "this is placeholder"
  },
  isMuted: {
    type: Boolean,
    default: true
  },
  muteText: {
    type: String,
    default: ""
  },
  enableInput: {
    type: Boolean,
    default: true
  },
  enableAt: {
    type: Boolean,
    default: true
  },
  enableDragUpload: {
    type: Boolean,
    default: true
  },
  enableTyping: {
    type: Boolean,
    default: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const editor = ref<InstanceType<typeof MessageInputEditor>>();
  const currentConversation = ref<IConversationModel>();
  TUIStore.watch(StoreName.CONV, {
    currentConversation: (conversation: IConversationModel) => {
      currentConversation.value = conversation;
    }
  });
  const onTyping = (inputContentEmpty: boolean, inputBlur: boolean) => {
    sendTyping(inputContentEmpty, inputBlur);
  };
  const sendMessage = async () => {
    const editorContentList = editor.value?.getEditorContent();
    if (!editorContentList || !currentConversation.value) return;
    await sendMessages(editorContentList, currentConversation.value);
    emit("sendMessage");
    editor.value?.resetEditor();
  };
  const insertEmoji = (emoji: any) => {
    editor.value?.addEmoji(emoji);
  };
  const onAtListOpen = () => {
    if (isH5) {
      editor.value?.blur();
    }
  };
  const reEdit = (content: any) => {
    editor.value?.resetEditor();
    // resetReplyOrReference();
    editor.value?.setEditorContent(content);
  };
  return Object.assign({
    isPC,
    props,
    editor,
    onTyping,
    sendMessage,
    onAtListOpen
  }, {
    insertEmoji,
    reEdit
  });
};
__sfc_main.components = Object.assign({
  MessageInputEditor,
  MessageInputButton,
  MessageInputAt,
  MessageInputQuote
}, __sfc_main.components);
export default __sfc_main;
