
import { TUIChatService, TUIStore, StoreName, IConversationModel, SendMessageParams } from "@tencentcloud/chat-uikit-engine";
import { ref } from "../../../../adapter-vue";
import { emojiList, basicEmojiMap } from "../../utils/emojiList";
import { IEmojiList, IEmojiListItem } from "../../../../interface";
import { EMOJI_TYPE } from ".././../../../constant";
import Icon from "../../../common/Icon.vue";
import faceIcon from "../../../../assets/icon/face.png";
import { isPC, isUniFrameWork } from "../../../../utils/env";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emits = __ctx.emit;
  const list = ref<IEmojiList>(emojiList);
  const currentTabIndex = ref<number>(0);
  const currentTabItem = ref<IEmojiListItem>(list?.value[0]);
  const currentEmojiList = ref<Array<string>>(list?.value[0]?.list);
  const currentConversation = ref();
  const emojiPickerDialog = ref();
  const emojiPickerListRef = ref();
  TUIStore.watch(StoreName.CONV, {
    currentConversation: (conversation: IConversationModel) => {
      currentConversation.value = conversation;
    }
  });
  const toggleEmojiTab = (index: number) => {
    currentTabIndex.value = index;
    currentTabItem.value = list?.value[index];
    currentEmojiList.value = list?.value[index]?.list;
    // 滚动条回滚到顶部
    // 原生 web & h5
    if (!isUniFrameWork) {
      emojiPickerListRef?.value && (emojiPickerListRef.value.scrollTop = 0);
    }
  };
  const select = (item: any, index: number) => {
    const options: any = {
      name: item,
      type: currentTabItem?.value?.type
    };
    switch (currentTabItem?.value?.type) {
      case EMOJI_TYPE.BASIC:
        options.url = currentTabItem?.value?.url + basicEmojiMap[item];
        emits("insertEmoji", options);
        break;
      case EMOJI_TYPE.BIG:
        sendFaceMessage(index, currentTabItem.value);
        break;
      case EMOJI_TYPE.CUSTOM:
        sendFaceMessage(index, currentTabItem.value);
        break;
      default:
        break;
    }
    isPC && emits("onClose");
  };
  const sendFaceMessage = (index: number, listItem: IEmojiListItem) => {
    const options = {
      to: currentConversation?.value?.groupProfile?.groupID || currentConversation?.value?.userProfile?.userID,
      conversationType: currentConversation?.value?.type,
      payload: {
        index: listItem.index,
        data: listItem.list[index]
      }
    } as SendMessageParams;
    TUIChatService.sendFaceMessage(options);
  };
  const sendMessage = () => {
    emits("sendMessage");
  };
  return {
    basicEmojiMap,
    EMOJI_TYPE,
    faceIcon,
    isPC,
    isUniFrameWork,
    list,
    currentTabItem,
    currentEmojiList,
    emojiPickerDialog,
    emojiPickerListRef,
    toggleEmojiTab,
    select,
    sendMessage
  };
};
__sfc_main.components = Object.assign({
  Icon
}, __sfc_main.components);
export default __sfc_main;
