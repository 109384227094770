const Words = {
  "请对本次服务进行评价": "Please rate this service",
  "提交评价": "Feedback",
  "常用语-快捷回复工具": "Common Expressions - Quick Reply Tool",
  "使用": "Build with",
  "自定义消息": "Custom message",
  "搭建": "",
  "在吗？在吗？在吗？重要的话说三遍。": "Are you there? ",
  "好久没聊天了，快来和我说说话～": "It’s been a long time. How about you?",
  "好的，就这么说定了。": "Okay. It's a deal.",
  "感恩的心，感谢有你。": "So grateful! ",
  "糟糕！是心动的感觉！": "Oh, no. Flipped.",
  "心疼地抱抱自己，我太难了！": "Give myself a warm hug. ",
  "没关系，别在意，事情过去就过去了。": "Take it easy.",
  "早上好，今天也是让人期待的一天呢！": "Good morning. Have a wonderful day.",
  "熬夜有什么用，又没人陪你聊天，早点休息吧。": "It's better to go to bed early."
}

export default Words;
