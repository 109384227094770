
import TUIChatEngine, { TUITranslateService, TUIConversationService, TUIStore, StoreName, IMessageModel, IConversationModel } from "@tencentcloud/chat-uikit-engine";
import TUICore, { TUIConstants, ExtensionInfo } from "@tencentcloud/tui-core";
import { ref, onUnmounted } from "../../adapter-vue";
import ChatHeader from "./chat-header/index.vue";
import MessageList from "./message-list/index.vue";
import MessageInput from "./message-input/index.vue";
import Forward from "./forward/index.vue";
import MessageInputToolbar from "./message-input-toolbar/index.vue";
import { isPC, isWeChat, isUniFrameWork } from "../../utils/env";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const emits = __ctx.emit;
  const isToolbarShow = ref<boolean>(!isUniFrameWork);
  const messageInputRef = ref();
  const currentConversationID = ref();
  // 是否显示群组管理
  const isGroup = ref(false);
  const groupID = ref("");
  const groupManageExt = ref<ExtensionInfo>(undefined);
  console.log('____________________________________________________________');
  TUIStore.watch(StoreName.CONV, {
    currentConversationID: (id: string) => {
      currentConversationID.value = id;
    },
    currentConversation: (conversation: IConversationModel) => {
      isGroup.value = conversation?.type === TUIChatEngine.TYPES.CONV_GROUP;
      if (isUniFrameWork && isGroup.value && groupID.value !== conversation?.groupProfile?.groupID) {
        const extList = TUICore.getExtensionList(TUIConstants.TUIChat.EXTENSION.CHAT_HEADER.EXT_ID, {
          filterManageGroup: isGroup.value
        });
        groupManageExt.value = extList[0];
      }
      if (isUniFrameWork && !isGroup.value) {
        groupManageExt.value = [];
      }
      groupID.value = conversation?.groupProfile?.groupID;
    }
  });
  onUnmounted(() => {
    reset();
  });

  // 清空当前 conversationID
  const reset = () => {
    TUIConversationService.switchConversation();
  };
  const closeChat = (conversationID: string) => {
    emits("closeChat", conversationID);
    reset();
  };
  const insertEmoji = (emojiObj: object) => {
    messageInputRef?.value?.insertEmoji(emojiObj);
  };
  const handleToolbarListShow = (show?: boolean) => {
    if (isUniFrameWork) {
      isToolbarShow.value = show ?? !isToolbarShow.value;
    } else {
      isToolbarShow.value = true;
    }
  };
  const handleEditor = (message: IMessageModel, type: string) => {
    if (!message || !type) return;
    switch (type) {
      case "reference":
        // todo
        break;
      case "reply":
        // todo
        break;
      case "reedit":
        if (message?.payload?.text) {
          messageInputRef?.value?.reEdit(message?.payload?.text);
        }
        break;
      default:
        break;
    }
  };
  const handleGroup = () => {
    groupManageExt.value.listener.onClicked({
      groupID: groupID.value
    });
  };
  return {
    TUITranslateService,
    isPC,
    isWeChat,
    isUniFrameWork,
    isToolbarShow,
    messageInputRef,
    currentConversationID,
    isGroup,
    groupID,
    groupManageExt,
    closeChat,
    insertEmoji,
    handleToolbarListShow,
    handleEditor,
    handleGroup
  };
};
__sfc_main.components = Object.assign({
  ChatHeader,
  Forward,
  MessageList,
  MessageInputToolbar,
  MessageInput
}, __sfc_main.components);
export default __sfc_main;
